import { useRef } from 'react'
import { useClickAway } from 'react-use'
import type { ImageResult } from '../data/ImageFragment'
import { Button } from './Button'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import s from './RecommendationFormSuccessModal.module.sass'

export type RecommendationFormSuccessModalProps = {
	image?: ImageResult
	title?: string
	text?: string
	button?: string
	onRequestClose?: () => void
}

export function RecommendationFormSuccessModal(props: RecommendationFormSuccessModalProps) {
	const ref = useRef<HTMLDivElement | null>(null)

	useClickAway(ref, () => {
		props.onRequestClose?.()
	})
	return (
		<div className={s.Wrapper} ref={ref}>
			{props.image && (
				<div>
					<ContemberImage image={props.image} />
				</div>
			)}
			<h3 className={s.Title}>{props.title}</h3>
			<p className={s.Text}>{props.text}</p>

			{props.button && (
				<Button type="button" onClick={() => props.onRequestClose?.()} variant="red" isFullWidth isWithIcon>
					{props.button}
					<span className={s.Checkmark}>
						<Icon name="checkmark" />
					</span>
				</Button>
			)}
		</div>
	)
}
