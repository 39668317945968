export function pluralize(count: number, template: string) {
	return template.replace(/\{(.*)\}/gi, (original, parts) => {
		const split = parts.replace(/#/g, String(count)).split(';')
		if (count === 1) {
			return split[0]
		} else if (count > 1 && count <= 4) {
			return split[1]
		} else {
			return split[2]
		}
	})
}
