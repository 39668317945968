import type { FunctionComponent } from 'react'
import { useMemo } from 'react'
import { usePageContext } from '../pages/[[...path]]'
import { Carousel } from './Carousel'

export type ArticleCarouselProps = {
	title?: string
	text?: string
	ids: string[]
}

export const ArticleCarousel: FunctionComponent<ArticleCarouselProps> = ({ title, text, ids }) => {
	const pageContext = usePageContext()

	const articles = useMemo(() => {
		if (ids.length > 0) {
			return pageContext?.articles?.filter((article) => ids.includes(article.id))
		} else {
			return pageContext?.newestArticles
		}
	}, [ids, pageContext?.articles, pageContext?.newestArticles])

	return <Carousel title={title} text={text} items={articles ?? []} />
}
