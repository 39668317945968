import { createContext, useContext, useEffect } from 'react'
import { z } from 'zod'
import { ContentReferenceType, One } from '../../generated/contember'
import { BusinessPage } from '../components/BusinessPage'
import { Footer } from '../components/Footer'
import { GenericPage } from '../components/GenericPage'
import { Header } from '../components/Header'
import { HomePage } from '../components/HomePage'
import { HomePageLokal } from '../components/HomePageLokal'
import { JobModalInfo } from '../components/JobModalInfo'
import { JobPage } from '../components/JobPage'
import type { CustomJobsFormData } from '../components/JobsFormModal'
import { Seo } from '../components/Seo'
import { BusinessPageLocaleFragment } from '../data/BusinessPageLocaleFragment'
import { FooterLocaleFragment } from '../data/FooterLocaleFragment'
import { GeneralLocaleFragment } from '../data/GeneralLocaleFragment'
import { GenericPageLocaleFragment } from '../data/GenericPageLocaleFragment'
import { HeaderLocaleFragment } from '../data/HeaderLocaleFragment'
import { HomePageLocaleFragment } from '../data/HomePageLocaleFragment'
import { HomePageLokalLocaleFragment } from '../data/HomePageLokalLocaleFragment'
import { JobPageLocaleFragment } from '../data/JobPageLocaleFragment'
import { LinkFragment } from '../data/LinkFragment'
import { combineUrl } from '../data/combineUrl'
import { ArticlesInput } from '../utilities/ArticlesInput'
import { contember } from '../utilities/contember'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { scalarResolver } from '../utilities/createScalarResolver'
import { filterNonEmpty } from '../utilities/filterNonEmpty'
import { getLinkableUrlFromContext } from '../utilities/getLinkableUrlFromContext'
import type { InferDataLoaderProps } from '../utilities/handlers'
import { handleGetStaticPaths, handleGetStaticProps } from '../utilities/handlers'

export type PageProps = InferDataLoaderProps<typeof getStaticProps>

export default function (props: PageProps) {
	const header = props.data.getHeaderLocale
	const { businessPage, genericPage, homePage, homePageLokal, jobPage } = props.data.getLinkable ?? {}
	const footer = props.data.getFooterLocale
	const { pages } = props

	const pageLocales = [businessPage, genericPage, homePage, homePageLokal, jobPage].filter(filterNonEmpty)[0]

	const customJobsFormModalData: CustomJobsFormData | undefined = jobPage
		? {
				jobPageId: jobPage.id,
				content: <JobModalInfo jobForm={jobPage} />,
			}
		: undefined

	// TODO: Remove when Chrome fixes the bug with scroll-behavior smooth
	useEffect(() => {
		// @ts-expect-error chrome
		const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !!window.chrome
		if (isChrome) {
			const style = document.createElement('style')
			style.innerHTML = `
				html {
					scroll-behavior: initial !important;
				}
			`
			document.head.appendChild(style)
		}
	}, [])

	return (
		<>
			<PageContext.Provider value={props}>
				<Seo {...props.seo} />
				{header && (
					<Header theme={props.theme ?? undefined} header={header} translations={pageLocales.root?.locales ?? []} />
				)}
				{pages.businessPage ? (
					<BusinessPage businessPage={pages.businessPage} />
				) : pages.genericPage ? (
					<GenericPage genericPage={pages.genericPage} />
				) : pages.homePage ? (
					<HomePage homePage={pages.homePage} />
				) : pages.homePageLokal ? (
					<HomePageLokal homePageLokal={pages.homePageLokal} />
				) : null}
				{jobPage && customJobsFormModalData && (
					<JobPage
						jobPage={jobPage}
						customJobsFormModalData={customJobsFormModalData}
						deactivatedMessage={props.data.getJobsFilterLocale?.deactivatedJob}
					/>
				)}
				{footer && (
					<Footer footer={footer} theme={props.theme ?? undefined} customJobsFormModalData={customJobsFormModalData} />
				)}
			</PageContext.Provider>
		</>
	)
}

export const getStaticPaths = handleGetStaticPaths(async (context) => {
	const { listLinkable } = await contember('query', { scalars: scalarResolver })({
		listLinkable: [
			{
				filter: {
					redirect: { id: { isNull: true } },
				},
			},
			{
				id: true,
				url: true,
			},
		],
	})

	return {
		paths: listLinkable.map((link) => {
			const path = link.url.split('/').filter((part) => part !== '')

			let locale: string | undefined

			if (context.locales?.includes(path[0])) {
				locale = path.shift()
			}

			return {
				locale,
				params: {
					path,
				},
			}
		}),
		fallback: 'blocking',
	}
})

export const getStaticProps = handleGetStaticProps(async (context) => {
	const { locale } = combineUrl(context)

	const url = getLinkableUrlFromContext(context)

	const data = await contember('query', { scalars: scalarResolver })({
		getGeneralLocale: [
			{
				by: {
					root: { unique: One.One },
					locale: { code: locale },
				},
			},
			GeneralLocaleFragment(),
		],
		getHeaderLocale: [
			{
				by: {
					root: { unique: One.One },
					locale: { code: locale },
				},
			},
			HeaderLocaleFragment(),
		],
		getJobsFilterLocale: [
			{
				by: {
					root: { unique: One.One },
					locale: { code: locale },
				},
			},
			{ deactivatedJob: true },
		],
		getLinkable: [
			{
				by: { url },
			},
			{
				url: true,
				businessPage: [{}, BusinessPageLocaleFragment(locale)],
				genericPage: [{}, GenericPageLocaleFragment()],
				homePage: [{}, HomePageLocaleFragment()],
				homePageLokal: [{}, HomePageLokalLocaleFragment()],
				jobPage: [{}, JobPageLocaleFragment(locale)],
				redirect: [
					{},
					{
						id: true,
						target: [{}, LinkFragment()],
					},
				],
			},
		],
		getFooterLocale: [
			{
				by: {
					root: { unique: One.One },
					locale: { code: locale },
				},
			},
			FooterLocaleFragment(),
		],
	})

	const redirectUrl = (() => {
		const target = data.getLinkable?.redirect?.target
		return target ? contemberLinkToHref(target) : null
	})()

	if (redirectUrl) {
		return {
			redirect: {
				permanent: false,
				destination: redirectUrl,
			},
			revalidate: 60,
		}
	}

	const canonicalUrl = (() => {
		const url = data.getLinkable?.url
		if (!url) {
			return null
		}
		return (process.env.NEXT_PUBLIC_WEB_URL ?? '') + url
	})()

	const content =
		data.getLinkable?.businessPage?.content ||
		data.getLinkable?.genericPage?.content ||
		data.getLinkable?.homePage?.content ||
		data.getLinkable?.homePageLokal?.content ||
		data.getLinkable?.jobPage?.content

	const articleBlocks = content?.blocks.flatMap((block) => {
		return block.references.filter((reference) => reference.type === ContentReferenceType.articlesCarousel)
	})
	const articleIds: string[] = []
	articleBlocks?.forEach((articleBlock) => {
		articleBlock.textList?.items.forEach((textItem) => {
			if (textItem.text && !articleIds.find((item) => textItem.text === item)) {
				articleIds.push(textItem.text)
			}
		})
	})

	const articlesResponse =
		articleIds.length > 0
			? await fetch('https://jidloaradost.vercel.app/api/v1/articles', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ articleIds: articleIds }),
				})
			: null
	const articlesData = articlesResponse ? await articlesResponse.json() : null

	const articlesSchema = z.array(ArticlesInput())
	const schema = z.object({
		ok: z.boolean(),
		newest: articlesSchema,
		other: articlesSchema,
	})
	const validatedArticlesData = articlesData ? schema.parse(articlesData) : null

	const pages = {
		businessPage: data.getLinkable?.businessPage,
		genericPage: data.getLinkable?.genericPage,
		homePage: data.getLinkable?.homePage,
		homePageLokal: data.getLinkable?.homePageLokal,
		jobPage: data.getLinkable?.jobPage,
	}

	const page = Object.values(pages).find((page) => Boolean(page))

	if (!page) {
		return {
			notFound: true,
			revalidate: 60,
		}
	}

	const theme = page.root && 'theme' in page.root ? page.root.theme : null

	return {
		props: {
			locale,
			data,
			pages,
			newestArticles: validatedArticlesData?.newest ?? null,
			articles: validatedArticlesData?.other ?? null,
			seo: {
				canonicalUrl,
				seo: {
					...(data.getGeneralLocale?.seo ?? {}),
					...Object.fromEntries(Object.entries(page.seo ?? {}).filter(([_, value]) => Boolean(value))),
				},
			},
			theme,
		},
		revalidate: 60,
	}
})

export const PageContext = createContext<null | PageProps>(null)

export function usePageContext() {
	const context = useContext(PageContext)
	if (!context) {
		throw new Error('Missing PageContext')
	}
	return context
}
