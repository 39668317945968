import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import type { JobPageLocaleResult } from '../data/JobPageLocaleFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './JobModalInfo.module.sass'

export type JobModalInfoProps = {
	jobForm: JobPageLocaleResult
}

export const JobModalInfo: FunctionComponent<JobModalInfoProps> = ({ jobForm }) => {
	return (
		<div className={s.Wrapper}>
			{jobForm.image && (
				<div className={s.Image}>
					<ContemberImage image={jobForm.image} layout="fill" objectFit="cover" />
				</div>
			)}
			<div className={s.HeaderContent}>
				<Container size="wide">
					<div className={s.HeaderContentIn}>
						<h1 className={s.Name}>{jobForm.title}</h1>
						{jobForm.text && <RichTextRenderer source={jobForm.text} />}
						{jobForm.keyInfo && (
							<div className={s.KeyInfoBox}>
								{jobForm.keyInfo?.items.map((item) => (
									<div key={item.id} className={s.KeyInfoWrapper}>
										{item.icon?.icon && (
											<div className={s.KeyIcon}>
												<ContemberImage image={item.icon.icon} />
											</div>
										)}
										<p className={s.KeyInfo}>{item.keyInfo}</p>
									</div>
								))}
							</div>
						)}
					</div>
				</Container>
			</div>
		</div>
	)
}
