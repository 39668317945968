import type { BusinessPageLocaleResult } from '../data/BusinessPageLocaleFragment'
import s from './BusinessPage.module.sass'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { Hero } from './Hero'
import { StickyBar } from './StickyBar'

export type BusinessPageProps = {
	businessPage: BusinessPageLocaleResult
}

export function BusinessPage(props: BusinessPageProps) {
	return (
		<Container size="wide">
			<div className={s.Wrapper}>
				<Hero
					title={props.businessPage.name}
					subtitle={props.businessPage.subtitle}
					text={props.businessPage.text}
					image={props.businessPage.image}
					businessPage
					infoBox={props.businessPage.infoBox?.localesByLocale}
				/>

				{props.businessPage.content && (
					<div className={s.Content}>
						<ContentRenderer content={props.businessPage.content} />
					</div>
				)}
				{props.businessPage.stickyBar && <StickyBar stickyBar={props.businessPage.stickyBar} />}
			</div>
		</Container>
	)
}
