// Reexport all svg icons
export { default as arrow } from './arrow.svg'
export { default as arrowDown } from './arrowDown.svg'
export { default as checkmark } from './checkmark.svg'
export { default as close } from './close.svg'
export { default as facebook } from './facebook.svg'
export { default as globe } from './globe.svg'
export { default as hamburger } from './hamburger.svg'
export { default as instagram } from './instagram.svg'
export { default as linkedin } from './linkedin.svg'
export { default as logo } from './logo.svg'
export { default as logoHover } from './logoHover.svg'
export { default as minus } from './minus.svg'
export { default as play } from './play.svg'
export { default as plus } from './plus.svg'
export { default as read } from './read.svg'
export { default as twitter } from './twitter.svg'
