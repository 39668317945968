import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import type { FunctionComponent } from 'react'
import { useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { Icon } from './Icon'
import s from './JobFilter.module.sass'

export type JobFilterProps = {
	heading: string
	filters: Array<{
		slug: string
		label: string | undefined
	}>
	activeSlugs: string[]
	setActiveSlugs: (slugs: string[]) => void
}

export const JobFilter: FunctionComponent<JobFilterProps> = ({ heading, filters, setActiveSlugs, activeSlugs }) => {
	const [open, setOpen] = useState(false)

	const ref = useRef<HTMLDivElement | null>(null)

	useClickAway(ref, () => {
		setOpen(false)
	})

	return (
		<div className={s.FilterSectionWrapper} ref={ref}>
			<button
				className={clsx(s.CollapsibleTitleWrapper, open && s.isOpen)}
				onClick={() => {
					setOpen(!open)
				}}
			>
				<p className={s.CollapsibleTitle}>{heading}</p>
				{open ? (
					<div className={clsx(s.Arrow, s.Close)}>
						<Icon name="arrowDown" />
					</div>
				) : (
					<div className={clsx(s.Arrow, s.Open)}>
						<Icon name="arrowDown" />
					</div>
				)}
			</button>
			<div className={s.FilterSectionWrapper}>
				<Collapsible open={open}>
					<div className={s.FilterSection}>
						<div className={s.Pointer} />
						<div className={s.PointerShade} />
						<div className={s.FiltersWrapper}>
							{filters.map(
								(item) =>
									item && (
										<label key={item.slug} className={s.InputWrapper}>
											<p className={s.InputLabel}>{item.label}</p>
											<input
												checked={activeSlugs.includes(item.slug)}
												className={s.Input}
												type="checkbox"
												onChange={(event) => {
													const newActiveSlugs = filters
														.map((item) => item.slug)
														.filter((slug) => {
															if (slug === item.slug) {
																return event.target.checked
															}
															return activeSlugs.includes(slug)
														})

													setActiveSlugs(newActiveSlugs)
												}}
											/>
											<span className={s.Check}>
												<span className={s.CheckmarkIcon}>
													<Icon name="checkmark" />
												</span>
											</span>
										</label>
									),
							)}
						</div>
					</div>
				</Collapsible>
			</div>
		</div>
	)
}
