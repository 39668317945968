import { RichTextRenderer } from '@contember/react-client'
import type { JobsFormLocaleResult } from '../data/JobsFormLocaleFragment'
import type { JobsFormSuccessOtherPagesResult } from '../data/JobsFormSuccessOtherPagesFragment'
import { CarouselNavigation } from './CarouselNavigation'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import { JobsForm } from './JobsForm'
import s from './JobsFormModal.module.sass'
import { useCarousel } from './useCarousel'

export type CustomJobsFormData = {
	content: React.ReactNode
	jobPageId: string
}

export type JobsFormModalProps = {
	jobsFormModal: JobsFormLocaleResult
	otherJobs?: JobsFormSuccessOtherPagesResult
	customModalData?: CustomJobsFormData
	onRequestClose?: () => void
}

export function JobsFormModal(props: JobsFormModalProps) {
	const { cardsElement, onSlideChange, showPreviousButton, showNextButton, scroll } = useCarousel()
	return (
		<div className={s.Wrapper}>
			<Container size="wide" disableGutters>
				<div className={s.MinimalizeWrapper}>
					{props.jobsFormModal.logo && (
						<div>
							<ContemberImage image={props.jobsFormModal.logo} />
						</div>
					)}
					<button className={s.MinimalizeBtn} type="button" onClick={() => props.onRequestClose?.()}>
						<p className={s.MinimalizeLabel}>{props.jobsFormModal.minimalize}</p>
						<Icon name="arrowDown" />
					</button>
				</div>
				<div className={s.Container}>
					<div className={s.FormMobileTitleWrapper}>
						<h3 className={s.Title}>{props.jobsFormModal.title}</h3>
						{props.customModalData?.content && props.jobsFormModal.jobText && (
							<div>
								<RichTextRenderer source={props.jobsFormModal.jobText} />
							</div>
						)}
						{!props.customModalData?.content && props.jobsFormModal.genericText && (
							<div>
								<RichTextRenderer source={props.jobsFormModal.genericText} />
							</div>
						)}
					</div>
					<div className={s.LeftContent}>
						{props.customModalData?.content ? (
							props.customModalData?.content
						) : (
							<div className={s.ImageTextList}>
								<div className={s.Carousel} ref={cardsElement} onScroll={() => onSlideChange()}>
									{props.jobsFormModal.imageTextList?.items.map((item) => (
										<div key={item.id} className={s.CarouselItem}>
											{item.image && (
												<div className={s.CarouselImage}>
													<ContemberImage image={item.image} objectFit="cover" layout="fill" />
												</div>
											)}
											<div className={s.CarouselTextWrapper}>
												<p className={s.CarouselText}>{item.title}</p>
												<CarouselNavigation
													onPreviousClick={() => scroll(-1)}
													onNextClick={() => scroll(1)}
													isPreviousButtonVisible={showPreviousButton}
													isNextButtonVisible={showNextButton}
												/>
											</div>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
					<div className={s.FormWrapper}>
						<div className={s.FormWrapperIn}>
							<div className={s.FormDesktopTitleWrapper}>
								<h3 className={s.Title}>{props.jobsFormModal.title}</h3>
								{props.customModalData?.content && props.jobsFormModal.jobText && (
									<div>
										<RichTextRenderer source={props.jobsFormModal.jobText} />
									</div>
								)}
								{!props.customModalData?.content && props.jobsFormModal.genericText && (
									<div>
										<RichTextRenderer source={props.jobsFormModal.genericText} />
									</div>
								)}
							</div>
							<JobsForm jobsForm={props.jobsFormModal} onRequestClose={props.onRequestClose} />
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}
