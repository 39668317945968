import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/ImageFragment'
import type { InfoBoxLocaleResult } from '../data/InfoBoxLocaleFragment'
import type { KeyInfoListResult } from '../data/KeyInfoFragment'
import { usePageContext } from '../pages/[[...path]]'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Hero.module.sass'
import { Icon } from './Icon'
import { InfoBox } from './InfoBox'

export type HeroProps = {
	title?: string
	subtitle?: string
	text?: string
	logo?: ImageResult
	mobileImage?: ImageResult
	image?: ImageResult
	keyInfo?: KeyInfoListResult
	genericPage?: boolean
	businessPage?: boolean
	jobPage?: boolean
	infoBox?: InfoBoxLocaleResult
}

export const Hero: FunctionComponent<HeroProps> = ({
	title,
	subtitle,
	text,
	logo,
	mobileImage,
	image,
	keyInfo,
	genericPage,
	businessPage,
	jobPage,
	infoBox,
}) => {
	const page = usePageContext()
	return (
		<div className={s.Header}>
			{mobileImage ? (
				<div className={s.MobileImage}>
					<ContemberImage image={mobileImage} layout="fill" objectFit="cover" />
				</div>
			) : (
				image && (
					<div className={s.MobileImage}>
						<ContemberImage image={image} layout="fill" objectFit="cover" />
					</div>
				)
			)}
			{image && (
				<div className={s.Image}>
					<ContemberImage image={image} layout="fill" objectFit="cover" />
				</div>
			)}
			<div className={clsx(s.HeaderContent, image && s.isWithImage)}>
				<Container size="wide">
					{page?.data.getLinkable?.jobPage?.root?.business?.localesByLocale?.link?.url && (
						<Button
							href={page.data.getLinkable.jobPage.root.business.localesByLocale.link.url}
							type="link"
							variant="white"
							className={s.BackButton}
						>
							<span className={s.ButtonArrow}>
								<Icon name="arrow" />
							</span>
							Zpět na profil
						</Button>
					)}
				</Container>
				<Container size={genericPage ? 'normal' : 'wide'}>
					<div className={s.HeaderContentIn}>
						{logo && (
							<div className={s.LogoMobile}>
								<ContemberImage image={logo} />
							</div>
						)}
						<div className={s.NameWrapper}>
							<h1 className={s.Name}>{title}</h1>
							{subtitle && <p className={s.Subtitle}>{subtitle}</p>}
							{text && (
								<div
									className={clsx(
										genericPage && s.genericPageText,
										businessPage && s.businessPageText,
										jobPage && s.jobPageText,
									)}
								>
									<RichTextRenderer source={text} />
								</div>
							)}
						</div>
						{logo && (
							<div className={s.LogoDesktop}>
								<ContemberImage image={logo} />
							</div>
						)}
						{keyInfo && (
							<div className={s.KeyInfoBox}>
								{keyInfo?.items.map((item) => (
									<div key={item.id} className={s.KeyInfoWrapper}>
										{item.icon?.icon && (
											<div className={s.KeyIcon}>
												<ContemberImage image={item.icon.icon} />
											</div>
										)}
										<p className={s.KeyInfo}>{item.keyInfo}</p>
									</div>
								))}
							</div>
						)}
						{infoBox && <InfoBox infoBox={infoBox} />}
					</div>
				</Container>
			</div>
		</div>
	)
}
