import { useCallback, useEffect, useRef, useState, type FunctionComponent } from 'react'
import type { VideoResult } from '../data/VideoFragment'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import s from './Video.module.sass'

export type VideoProps = {
	video: VideoResult
	onEnded?: () => void
}

export const Video: FunctionComponent<VideoProps> = ({ video, onEnded }) => {
	const [isActiveVideo, setIsActiveVideo] = useState(false)

	const videoRef = useRef<HTMLVideoElement>(null)

	useEffect(() => {
		const video = videoRef.current
		if (video === null) {
			return
		}
		if (isActiveVideo) {
			try {
				video.play()
			} catch (error) {
				// Play intentionally ignored
			}
		}
	}, [isActiveVideo])

	const handleEnded = useCallback(() => {
		setIsActiveVideo(false)
		onEnded?.()
	}, [onEnded])

	return (
		<div className={s.Wrapper}>
			<video className={s.Video} playsInline controls={true} ref={videoRef} onEnded={handleEnded}>
				<source src={video.src} />
			</video>

			{video.poster && !isActiveVideo && (
				<button className={s.PosterButton} onClick={() => setIsActiveVideo(true)}>
					<ContemberImage image={video.poster} layout="fill" objectFit="cover" />
					<div className={s.Play}>
						<Icon name="play" />
					</div>
				</button>
			)}
		</div>
	)
}
