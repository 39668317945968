import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import type { StickyBarResult } from '../data/StickyBarFragment'
import { usePageContext } from '../pages/[[...path]]'
import { Button } from './Button'
import { Container } from './Container'
import { JobModalInfo } from './JobModalInfo'
import type { CustomJobsFormData } from './JobsFormModal'
import { JobsFormModal } from './JobsFormModal'
import { Link } from './Link'
import s from './StickyBar.module.sass'

export type StickyBarProps = {
	stickyBar: StickyBarResult
}

export const StickyBar: FunctionComponent<StickyBarProps> = ({ stickyBar }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const pageProps = usePageContext()

	const customJobsFormModalData: CustomJobsFormData | undefined = pageProps?.data.getLinkable?.jobPage
		? {
				jobPageId: pageProps.data.getLinkable.jobPage.id,
				content: <JobModalInfo jobForm={pageProps.data.getLinkable.jobPage} />,
			}
		: undefined
	const customStyles = {
		content: {
			inset: '0',
			border: 'none',
			borderRadius: '0',
		},
		overlay: {
			zIndex: 2,
		},
	}

	useEffect(() => {
		if (isModalOpen) {
			document.body.style.position = isModalOpen ? 'fixed' : 'relative'
		} else {
			document.body.style.position = 'relative'
		}
	}, [isModalOpen])

	return stickyBar.title ? (
		<div className={s.Container}>
			<Container size="wide">
				<div className={s.Wrapper}>
					{stickyBar.title && <p className={s.Title}>{stickyBar.title}</p>}

					{stickyBar.link?.title && (
						<Link link={stickyBar.link} className={s.Link}>
							<p className={s.FormButtonLabel}>{stickyBar.link.title}</p>
						</Link>
					)}
					{stickyBar.formButtonLabel && (
						<Button type="button" onClick={() => setIsModalOpen(true)} variant="red">
							<p className={s.FormButtonLabel}>{stickyBar.formButtonLabel}</p>
						</Button>
					)}
					{stickyBar.jobsForm && (
						<Modal isOpen={isModalOpen} style={customStyles}>
							<JobsFormModal
								jobsFormModal={stickyBar.jobsForm}
								onRequestClose={() => {
									setIsModalOpen(false)
								}}
								customModalData={customJobsFormModalData}
							/>
						</Modal>
					)}
				</div>
			</Container>
		</div>
	) : null
}
