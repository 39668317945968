import { RichTextRenderer } from '@contember/react-client'
import { useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import type { ImageResult } from '../data/ImageFragment'
import type { JobPageTileLocaleResult } from '../data/JobPageTileLocaleFragment'
import type { JobsFormSuccessOtherPagesResult } from '../data/JobsFormSuccessOtherPagesFragment'
import { pluralize } from '../utilities/pluralize'
import { Button } from './Button'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import s from './JobsFormSuccessModal.module.sass'

export type JobsFormSuccessModalProps = {
	image?: ImageResult
	title?: string
	text?: string
	button?: string
	otherJobs?: JobsFormSuccessOtherPagesResult
	onRequestClose?: () => void
	upsell?: JobPageTileLocaleResult[]
	onSubmit: (jobPositionIds: string[]) => void
}

export function JobsFormSuccessModal(props: JobsFormSuccessModalProps) {
	const ref = useRef<HTMLDivElement | null>(null)

	const [selectedPositions, setSelectedPositions] = useState<string[]>([])

	const message = props.otherJobs?.successOtherJobsSelectionTitle

	pluralize(selectedPositions.length, message ?? '')

	useClickAway(ref, () => {
		props.onRequestClose?.()
	})
	return (
		<div className={s.Wrapper} ref={ref}>
			{props.upsell && (
				<button className={s.Close} onClick={() => props.onRequestClose?.()} type="button">
					<Icon name="close" />
				</button>
			)}
			{props.image && (
				<div>
					<ContemberImage image={props.image} />
				</div>
			)}
			<h3 className={s.Title}>{props.title}</h3>
			<p className={s.Text}>{props.text}</p>

			{!props.upsell && props.button && (
				<Button type="button" onClick={() => props.onRequestClose?.()} variant="red" isFullWidth isWithIcon>
					{props.button}
					<span className={s.Checkmark}>
						<Icon name="checkmark" />
					</span>
				</Button>
			)}
			{props.upsell && (
				<>
					<div className={s.Line} />
					<p className={s.SuccessOtherJobsTitle}>{props.otherJobs?.successOtherJobsTitle}</p>
					<div className={s.OtherPagesTitleWrapper}>
						{props.otherJobs?.successOtherJobsCategoryTitle}{' '}
						{props.upsell?.[0].root?.jobPosition?.localesByLocale?.type}
						<button
							type="button"
							className={s.SuccessOtherJobsButtonTitle}
							onClick={() => {
								setSelectedPositions((old) => {
									const allChecked = props.upsell?.map((jp) => jp.id) ?? []
									if (allChecked.length > old.length) {
										return allChecked
									} else {
										return []
									}
								})
							}}
						>
							{props.otherJobs?.successOtherJobsButtonTitle}
						</button>
					</div>

					<div className={s.Jobs}>
						{props.upsell?.map((jobPosition) => (
							<div key={jobPosition.id} className={s.Job}>
								{jobPosition.image && (
									<div className={s.JobImage}>
										<ContemberImage objectFit="cover" image={jobPosition.image} />
									</div>
								)}
								<div className={s.JobInfoBox}>
									<div className={s.TitleWrapper}>
										<div>
											<h4 className={s.JobTitle}>{jobPosition.title}</h4>
											{jobPosition.text && (
												<div className={s.JobText}>
													<RichTextRenderer source={jobPosition.text} />
												</div>
											)}
										</div>
										<label className={s.InputWrapper}>
											<input
												checked={selectedPositions.includes(jobPosition.id)}
												className={s.Input}
												type="checkbox"
												onChange={(e) => {
													const checked = e.currentTarget.checked
													setSelectedPositions((old) => {
														if (checked) {
															return [...old, jobPosition.id]
														} else {
															return old.filter((id) => id !== jobPosition.id)
														}
													})
												}}
											/>
											<span className={s.Check}>
												<span className={s.CheckmarkIcon}>
													<Icon name="checkmark" />
												</span>
											</span>
											<div className={s.InputLabel}>vybrat</div>
										</label>
									</div>
									<div className={s.JobLabels}>
										<p className={s.JobLabel}>{jobPosition.root?.contractType?.localesByLocale?.type}</p>
										<p className={s.JobLabel}>{jobPosition.root?.jobCity?.localesByLocale?.name}</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</>
			)}
			{selectedPositions.length > 0 && !!props.upsell?.length && (
				<div className={s.BottomBar}>
					<p className={s.SuccessOtherJobsSelectionTitle}>{pluralize(selectedPositions.length, message ?? '')}</p>
					<div className={s.Buttons}>
						<div className={s.Button}>
							<Button
								onClick={() => {
									props.onSubmit(selectedPositions)
								}}
								type="button"
								variant="red"
							>
								{props.otherJobs?.successOtherJobsSelectionYesButton}
							</Button>
						</div>
						<div className={s.Button}>
							<Button onClick={() => props.onRequestClose?.()} type="button">
								{props.otherJobs?.successOtherJobsSelectionNoButton}
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
