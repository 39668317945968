/* eslint-disable no-mixed-spaces-and-tabs */
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { useContext, useState } from 'react'
import Modal from 'react-modal'
import { PageThemeContext } from '../contexts/pageThemeContext'
import type { SideBySideResult } from '../data/SideBySideFragment'
import { usePageContext } from '../pages/[[...path]]'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { JobModalInfo } from './JobModalInfo'
import type { CustomJobsFormData } from './JobsFormModal'
import { JobsFormModal } from './JobsFormModal'
import s from './SideBySideContent.module.sass'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { CarouselNavigationSwiper } from './CarouselNavigationSwiper'

export type SideBySideContentProps = {
	sideBySide: SideBySideResult
}

export const SideBySideContent: FunctionComponent<SideBySideContentProps> = ({ sideBySide }) => {
	const theme = useContext(PageThemeContext)
	const pageProps = usePageContext()

	const customJobsFormModalData: CustomJobsFormData | undefined = pageProps?.data.getLinkable?.jobPage
		? {
				jobPageId: pageProps.data.getLinkable.jobPage.id,
				content: <JobModalInfo jobForm={pageProps.data.getLinkable.jobPage} />,
			}
		: undefined

	const [isModalOpen, setIsModalOpen] = useState(false)

	const customStyles = {
		content: {
			inset: '0',
			border: 'none',
			borderRadius: '0',
		},
		overlay: {
			zIndex: 2,
		},
	}

	return (
		<div
			className={clsx(
				s.Wrapper,
				sideBySide.background === 'red' && s.isRed,
				sideBySide.background === 'lightGrey' && s.isLightGrey,
			)}
		>
			<Container size="wide">
				<Swiper className={s.Carousel} slidesPerView={1} initialSlide={0} loop={false}>
					{sideBySide.items.map((item) => (
						<SwiperSlide key={item.id} className={s.CarouselItem}>
							<div
								className={clsx(
									s.SideBySideContent,
									s[`isText_${sideBySide.alignment}`],
									s[`isSize_${sideBySide.mediumSize}`],
									!item.text && s[`isSize_big`] && s.noTextAndBig,
								)}
							>
								<div className={s.Box}>
									<div className={s.Content}>
										<>
											{item.title && (
												<h2 className={s.Title}>
													<RichTextRenderer source={item.title} />
												</h2>
											)}
											{item.text && (
												<div className={s.Text}>
													<RichTextRenderer source={item.text} />
												</div>
											)}
											{item.link && (
												<div className={s.Link}>
													<Button type="link" href={contemberLinkToHref(item.link)} theme={theme}>
														{item.link?.title}
													</Button>
												</div>
											)}
											{item.formButtonLabel && (
												<div className={s.Link}>
													<Button type="button" onClick={() => setIsModalOpen(true)} theme={theme}>
														{item.formButtonLabel}
													</Button>
												</div>
											)}
											{item.jobsForm && (
												<Modal isOpen={isModalOpen} style={customStyles}>
													<JobsFormModal
														jobsFormModal={item.jobsForm}
														onRequestClose={() => {
															setIsModalOpen(false)
														}}
														customModalData={customJobsFormModalData}
													/>
												</Modal>
											)}
										</>
									</div>
								</div>
								<div className={clsx(s.Box, s.BoxImage, s[`isSize_${sideBySide.mediumSize}`])}>
									{item.media?.items.map((item) => {
										const type = item.medium?.type
										return (
											<div key={item.id} className={clsx(s.BoxIn, s[`is_${type}`])}>
												{type === 'image' && item.medium?.image ? (
													<div
														className={s.Image}
														style={{
															'--SideBySideContent-image-width': item.medium.image.width,
															'--SideBySideContent-image-height': item.medium.image.height,
														}}
													>
														<div className={s.ImageIn}>
															<ContemberImage image={item.medium.image} />
														</div>
													</div>
												) : type === 'video' && item.medium?.video ? (
													// eslint-disable-next-line jsx-a11y/media-has-caption
													<video
														className={s.video}
														autoPlay
														loop
														muted
														playsInline
														poster={item.medium.video?.poster?.url}
														controls
													>
														<source src={item.medium.video.src} type={item.medium.video.type} />
													</video>
												) : null}
											</div>
										)
									})}
								</div>
							</div>
						</SwiperSlide>
					))}
					{sideBySide.items.length > 1 && (
						<div
							style={{
								zIndex: 1,
							}}
							className={clsx(s.Navigation, s[`isText_${sideBySide.alignment}`])}
						>
							<CarouselNavigationSwiper />
						</div>
					)}
				</Swiper>
			</Container>
		</div>
	)
}
