import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { Collapsible as ExternalCollapsible } from 'collapsible-react-component'
import 'collapsible-react-component/dist/index.css'
import { useState, type FunctionComponent } from 'react'
import type { TextListResult } from '../data/TextListFragment'
import s from './Collapsible.module.sass'
import { Icon } from './Icon'

export type CollapsibleProps = {
	title?: string
	text?: string
	textList?: TextListResult
}

export const Collapsible: FunctionComponent<CollapsibleProps> = ({ title, text, textList }) => {
	return (
		<div className={s.Wrapper}>
			<div className={s.WrapperIn}>
				<div className={s.TitleWrapper}>
					<h3 className={s.Title}>{title}</h3>
					{text && (
						<div className={s.Text}>
							<RichTextRenderer source={text} />
						</div>
					)}
				</div>
				{textList?.items.map((item) => <CollapsibleItem key={item.id} {...item} />)}
			</div>
		</div>
	)
}
export const CollapsibleItem: FunctionComponent<NonNullable<CollapsibleProps['textList']>['items'][number]> = ({
	id,
	text,
	title,
}) => {
	const [open, setOpen] = useState(false)

	return (
		<>
			<button
				type="button"
				onClick={() => {
					setOpen(!open)
				}}
				className={clsx(s.CollapsibleTitleWrapper, open && s.isOpen)}
			>
				<h4 className={s.CollapsibleTitle}> {title}</h4>
				{open ? (
					<div className={s.Minus}>
						<Icon name="minus" />
					</div>
				) : (
					<div className={s.Plus}>
						<Icon name="plus" />
					</div>
				)}
				{/* </div> */}
			</button>
			<ExternalCollapsible key={id} open={open}>
				{text && (
					<div className={s.CollapsibleText}>
						<RichTextRenderer source={text} />
					</div>
				)}
			</ExternalCollapsible>
		</>
	)
}
