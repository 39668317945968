import { RichTextRenderer } from '@contember/react-client'
import type { CSSProperties, FunctionComponent } from 'react'
import type { TextListResult } from '../data/TextListFragment'
import { CarouselNavigation } from './CarouselNavigation'
import s from './Grid.module.sass'
import { useCarousel } from './useCarousel'

export type GridProps = {
	title: string
	text?: string
	grid?: TextListResult
}

export const Grid: FunctionComponent<GridProps> = ({ title, text, grid }) => {
	const { cardsElement, onSlideChange, showPreviousButton, showNextButton, scroll } = useCarousel()

	return (
		<div className={s.Wrapper}>
			<div className={s.WrapperIn}>
				<div className={s.TitleWrapper}>
					<h3 className={s.Title}>{title}</h3>
					{text && (
						<div className={s.Text}>
							<RichTextRenderer source={text} />
						</div>
					)}
				</div>
				{grid && (
					<div
						style={{ '--Grid-columns': grid.items.length / 2 + (grid.items.length % 2) } as CSSProperties}
						className={s.Grid}
						ref={cardsElement}
						onScroll={() => onSlideChange()}
					>
						{grid.items.map((item) => (
							<div key={item.id} className={s.GridItem}>
								<h4 className={s.GridTitle}>{item.title}</h4>
								{item.text && (
									<p className={s.GridText}>
										<RichTextRenderer source={item.text} />
									</p>
								)}
							</div>
						))}
					</div>
				)}
			</div>
			<CarouselNavigation
				onPreviousClick={() => scroll(-1)}
				onNextClick={() => scroll(1)}
				isPreviousButtonVisible={showPreviousButton}
				isNextButtonVisible={showNextButton}
			/>
		</div>
	)
}
