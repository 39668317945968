import clsx from 'clsx'
// import type { CarouselCardsProps } from './CarouselCards'
import s from './CarouselNavigation.module.sass'
import { Icon } from './Icon'
import { useSwiperReactive } from '../hooks/useSwiperReactive'

export const CarouselNavigationSwiper = () => {
	const swiper = useSwiperReactive()

	const isPreviousButtonVisible = !swiper.isBeginning
	const isNextButtonVisible = !swiper.isEnd
	return (
		<div className={clsx(s.Wrapper)}>
			<button
				type="button"
				className={clsx(
					s.Arrow,
					s.Previous,
					isNextButtonVisible && s.is_visible,
					isPreviousButtonVisible && s.is_clickable,
				)}
				onClick={() => swiper.slidePrev()}
				aria-label="previous"
			>
				<Icon name="arrow" />
			</button>
			<button
				type="button"
				className={clsx(s.Arrow, isPreviousButtonVisible && s.is_visible, isNextButtonVisible && s.is_clickable)}
				onClick={() => swiper.slideNext()}
				aria-label="next"
			>
				<Icon name="arrow" />
			</button>
		</div>
	)
}
