import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { PageThemeContext } from '../contexts/pageThemeContext'
import type { LinkResult } from '../data/LinkFragment'
import type { TextListResult } from '../data/TextListFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import s from './Statistics.module.sass'

export type StatisticsProps = {
	title?: string
	textList?: TextListResult
	link?: LinkResult
	isRed?: boolean
}

export const Statistics: FunctionComponent<StatisticsProps> = ({ title, textList, link, isRed }) => {
	const theme = useContext(PageThemeContext)

	return (
		<div className={clsx(s.Wrapper, !title && s.noTitle, !link && s.noButton)}>
			{title && <h3 className={s.Title}>{title}</h3>}
			<div className={s.List}>
				{textList?.items.map((item) => (
					<div key={item.id} className={s.Item}>
						<h4 className={clsx(s.ItemTitle, isRed && s.isRed)}>{item.title}</h4>
						{item.text && (
							<p className={s.ItemText}>
								<RichTextRenderer source={item.text} />
							</p>
						)}
					</div>
				))}
			</div>
			{link && (
				<div className={s.Button}>
					<Button type="link" href={contemberLinkToHref(link)} theme={theme}>
						{link?.title}
					</Button>
				</div>
			)}
		</div>
	)
}
