import clsx from 'clsx'
import type { InfoBoxLocaleResult } from '../data/InfoBoxLocaleFragment'
import { BreakableContent } from './BreakableContent'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import s from './InfoBox.module.sass'

export type InfoBoxProps = {
	infoBox: InfoBoxLocaleResult
}

export function InfoBox(props: InfoBoxProps) {
	return (
		<div className={s.Box}>
			{props.infoBox.root?.logo && (
				<div className={clsx(s.Logo, s[`view_size_${props.infoBox.root.logoSize}`])}>
					<ContemberImage image={props.infoBox.root.logo} />
				</div>
			)}
			<div className={s.Columns}>
				{props.infoBox.openingHours && props.infoBox.openingHours.items.length > 0 && (
					<div className={s.OpeningHours}>
						<div className={s.SectionLabel}>Otevírací doba:</div>
						{props.infoBox.openingHours?.items.map((item) => <div key={item.id}>{item.title}</div>)}
					</div>
				)}
				{props.infoBox.address && (
					<div className={s.AddressWrapper}>
						<div className={s.SectionLabel}>Adresa:</div>
						{props.infoBox.addressLink ? (
							<a href={props.infoBox.addressLink} target="_blank" className={s.Address}>
								<BreakableContent text={props.infoBox.address} />
							</a>
						) : (
							<div className={s.AddressNoUnderline}>
								<BreakableContent text={props.infoBox.address} />
							</div>
						)}
					</div>
				)}
			</div>

			<div className={s.Socials}>
				{props.infoBox.web && (
					<a className={s.Social} href={props.infoBox.web}>
						<Icon name="globe" />
					</a>
				)}
				{props.infoBox.instagram && (
					<a className={s.Social} href={props.infoBox.instagram}>
						<Icon name="instagram" />
					</a>
				)}
				{props.infoBox.facebook && (
					<a className={s.Social} href={props.infoBox.facebook}>
						<Icon name="facebook" />
					</a>
				)}
			</div>
		</div>
	)
}
