import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { Background } from '../../generated/contember'
import type { CarouselResult } from '../data/CarouselFragment'
import type { ArticlesInputData } from '../utilities/ArticlesInput'
import s from './Carousel.module.sass'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import { Link } from './Link'

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import { CarouselNavigationSwiper } from './CarouselNavigationSwiper'

export type CarouselItem = CarouselResult['items'][number] | ArticlesInputData

export type CarouselProps = {
	title?: string
	text?: string
	squareImages?: boolean
	background?: Background
	items: CarouselItem[]
}

export const Carousel: FunctionComponent<CarouselProps> = ({ title, text, squareImages, background, items }) => {
	return (
		<div
			className={clsx(
				s.Wrapper,
				background === 'red' && s.isRed,
				background === 'lightGrey' && s.isLight,
				background === 'lokal' && s.isLokalYellow,
			)}
		>
			<div className={s.WrapperIn}>
				<div className={s.TitleWrapper}>
					<h3 className={s.Title}>{title}</h3>
					{text && (
						<div className={s.Text}>
							<RichTextRenderer source={text} />
						</div>
					)}
				</div>
				<Swiper
					breakpoints={{
						0: {
							slidesPerView: 1,
						},
						400: {
							slidesPerView: 1.5,
						},
						639: {
							slidesPerView: 3,
						},
					}}
					initialSlide={0}
					loop={false}
					className={s.Carousel}
					spaceBetween={20}
					style={{ '--Carousel-slideCount': items.length }}
				>
					{items.map((item) => {
						// console.log(item)
						return 'link' in item ? (
							<SwiperSlide key={item.id}>
								<Link href={`https://jidloaradost.vercel.app/${item.link.url}`} className={s.CarouselLinkItem}>
									<CarouselItemContent item={item} squareImages={squareImages} />
								</Link>
							</SwiperSlide>
						) : (
							<SwiperSlide key={item.id}>
								{item.linkUrl ? (
									<Link link={item.linkUrl} className={s.CarouselLinkItem}>
										<CarouselItemContent item={item} squareImages={squareImages} />
									</Link>
								) : (
									<CarouselItemContent item={item} squareImages={squareImages} />
								)}
							</SwiperSlide>
						)
					})}
					<div
						style={{
							marginTop: '20px',
						}}
					>
						<CarouselNavigationSwiper />
					</div>
				</Swiper>
			</div>
		</div>
	)
}

export const CarouselItemContent: FunctionComponent<{
	item: CarouselProps['items'][number]
	squareImages: CarouselProps['squareImages']
}> = ({ item, squareImages }) => {
	return (
		<>
			{item.image && (
				<div className={clsx(s.CarouselImage, squareImages && s.SquareImages)}>
					<ContemberImage image={item.image} layout="fill" objectFit="cover" />
				</div>
			)}
			<p className={s.CarouselTitle}>{item.title}</p>
			{'text' in item && item.text && (
				<div className={s.CarouselText}>
					<RichTextRenderer source={item.text} />
				</div>
			)}
			{'leadParagraphForTiles' in item && <div className={s.CarouselText}>{item.leadParagraphForTiles}</div>}
			{'name' in item && <p className={s.CarouselName}>{item.name}</p>}
			{'views' in item && (
				<div className={s.CarouselViewsWrapper}>
					<span className={s.CarouselViewsIcon}>
						<Icon name="read" />
					</span>
					<p className={s.CarouselName}>{item.views}</p>
				</div>
			)}
		</>
	)
}
