import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import { useState } from 'react'
import Modal from 'react-modal'
import { useMirrorLoading } from 'shared-loading-indicator'
import type { JobsFormLocaleResult } from '../data/JobsFormLocaleFragment'
import { usePageContext } from '../pages/[[...path]]'
import { api } from '../utilities/api'
import { Button } from './Button'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import s from './JobsForm.module.sass'
import { JobsFormSuccessModal } from './JobsFormSuccessModal'
import { RichTextRenderer } from '@contember/react-client'

export type JobsFormProps = {
	jobsForm: JobsFormLocaleResult
	onRequestClose?: () => void
}

export function JobsForm(props: JobsFormProps) {
	const pageProps = usePageContext()

	const jobPageId = pageProps?.data.getLinkable?.jobPage?.id

	const jobFormLocaleId = props.jobsForm.id

	const [successModalOpen, setSuccessModalOpen] = useState(false)
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [message, setMessage] = useState('')
	const [file, setFile] = useState<File | null>(null)

	const backgroundColor = 'rgba(46, 46, 46, 0.6)'
	const customStyles = {
		content: {
			inset: 0,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100%',
			backgroundColor: backgroundColor,
			border: 'none',
			borderRadius: '0',
		},
		overlay: {
			zIndex: 2,
		},
	}

	function usePresignedUpload() {
		return useMutation(
			async (input: {
				file: File
				presign: { url: string; method: string; headers: { key: string; value: string }[] }
			}) => {
				return fetch(input.presign.url, {
					method: input.presign.method,
					headers: Object.fromEntries(input.presign.headers.map((header) => [header.key, header.value] as const)),
					body: input.file,
				})
			},
		)
	}

	const upload = usePresignedUpload()

	const mutation = api.jobFormSubscription.send.useMutation({
		async onSuccess(data) {
			if (data.presign && file?.size) {
				await upload.mutateAsync({ file, presign: data.presign })
			}
			setSuccessModalOpen(true)
		},
		onError: () => {
			alert('Něco se při odesílání pokazilo. Zkuste to znovu prosím později.')
		},
	})
	const isLoading = mutation.isLoading
	useMirrorLoading(isLoading)

	return (
		<form
			className={s.Form}
			onSubmit={(event) => {
				event.preventDefault()
				mutation.mutate({
					name,
					email,
					phone,
					message,
					jobPageIds: jobPageId ? [jobPageId] : [],
					jobFormLocaleId,
					acceptUpsell: true,
					upload: file?.size
						? {
								size: file.size,
								type: file.type,
								fileName: file.name,
							}
						: null,
				})
			}}
		>
			<label className={s.InputName}>
				<p className={s.InputLabel}>{props.jobsForm.nameLabel} *</p>
				<input
					type="text"
					className={s.Input}
					autoComplete="name"
					value={name}
					onChange={(event) => {
						setName(event.target.value)
					}}
					required
				/>
			</label>
			<label className={s.InputName}>
				<p className={s.InputLabel}>{props.jobsForm.emailLabel} *</p>
				<input
					type="email"
					className={s.Input}
					autoComplete="email"
					value={email}
					onChange={(event) => {
						setEmail(event.target.value)
					}}
					required
				/>
			</label>
			<label className={s.InputName}>
				<p className={s.InputLabel}>{props.jobsForm.telefonLabel} *</p>
				<input
					type="tel"
					className={s.Input}
					autoComplete="tel"
					value={phone}
					onChange={(event) => {
						setPhone(event.target.value)
					}}
					required
				/>
			</label>
			{file ? (
				<div className={clsx(s.InputFileWrapper, s.Uploaded)}>
					{props.jobsForm.root?.fileIlustration && (
						<div className={s.FileImage}>
							<ContemberImage image={props.jobsForm.root?.fileIlustration} />
						</div>
					)}
					<div>{file.name}</div>
					<button type="button" className={s.FileRemoveBtn} onClick={() => setFile(null)}>
						{props.jobsForm.fileRemoveBtnLabel}
					</button>
				</div>
			) : (
				<label>
					<div className={s.InputFileWrapper}>
						{props.jobsForm.root?.fileIlustration && (
							<div className={s.FileImage}>
								<ContemberImage image={props.jobsForm.root?.fileIlustration} />
							</div>
						)}
						<p className={s.FileTitle}>{props.jobsForm.fileTitleLabel}</p>
						<p className={s.FileTypeLabel}>{props.jobsForm.fileFormatLabel}</p>
						<input
							onChange={(e) => {
								const file = e.target.files?.[0]
								if (file?.size) {
									setFile(file)
								}
								e.currentTarget.value = ''
							}}
							type="file"
							name="upload"
							className={s.InputFile}
						/>
					</div>
				</label>
			)}
			<label>
				<textarea
					rows={5}
					name="message"
					className={s.TextAreaInput}
					placeholder={props.jobsForm.formTextLabel}
					value={message}
					onChange={(event) => {
						setMessage(event.target.value)
					}}
				/>
			</label>

			<div className={s.Submit}>
				<Button type="submit" variant="red" isFullWidth isWithIcon disabled={isLoading}>
					{props.jobsForm.submitLabel}
					<span className={s.Checkmark}>
						<Icon name="checkmark" />
					</span>
				</Button>
			</div>
			<label
				className={clsx(s.InputName, s.InputNameGdpr)}
				style={{
					alignItems: 'center',
				}}
			>
				{props.jobsForm.gdprLabel && (
					<p className={clsx(s.InputLabel, s.InputLabelGdpr)}>
						<RichTextRenderer source={props.jobsForm.gdprLabel} /> *
					</p>
				)}
				<input className={s.CheckBoxInput} type="checkbox" required />
				<span className={s.Check}>
					<span className={s.CheckmarkGdpr}>
						<Icon name="checkmark" />
					</span>
				</span>
			</label>

			<Modal isOpen={successModalOpen} style={customStyles}>
				<JobsFormSuccessModal
					image={props.jobsForm.successImage}
					title={props.jobsForm.successTitle}
					text={props.jobsForm.successText}
					button={props.jobsForm.successBtnLabel}
					// otherJobs={mutation.data?.otherJobs}
					onRequestClose={() => {
						props.onRequestClose?.()
						setSuccessModalOpen(false)
					}}
					onSubmit={(jobPositionIds) => {
						if (jobPositionIds.length) {
							mutation.mutate({
								name,
								email,
								phone,
								message,
								jobPageIds: jobPositionIds,
								jobFormLocaleId,
								acceptUpsell: false,
							})
						}
					}}
					// upsell={mutation.data?.upsell}
				/>
			</Modal>
		</form>
	)
}
