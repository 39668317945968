import { useEffect, useMemo, useState } from 'react'
import type { HomePageLocaleResult } from '../data/HomePageLocaleFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import s from './HomePage.module.sass'
import { SiteSwitcher } from './SiteSwitcher'
import { StickyBar } from './StickyBar'

export type HomePageProps = {
	homePage: HomePageLocaleResult
}

export function HomePage(props: HomePageProps) {
	const [imageIndex, setImageIndex] = useState<number | null>(0)
	useEffect(() => {
		if (!props.homePage.ilustration) {
			return
		}
		setImageIndex(Math.floor(Math.random() * props.homePage.ilustration.items.length))
	}, [props.homePage.ilustration])

	const image = useMemo(() => {
		if (imageIndex === null) {
			console.log(imageIndex)
			return null
		}
		return props.homePage.ilustration?.items[imageIndex].image
	}, [imageIndex, props.homePage.ilustration?.items])

	return (
		<div className={s.Wrapper}>
			<div className={s.Header}>
				<div className={s.HeaderIn}>
					<div className={s.LeftColumn}>
						{props.homePage.logo && (
							<div className={s.Logo}>
								<ContemberImage image={props.homePage.logo} objectFit="cover" />
							</div>
						)}
						<h1 className={s.Name}>{props.homePage.title}</h1>
						<div className={s.Text}>{props.homePage.text}</div>
						{props.homePage.button && (
							<Button type="link" href={contemberLinkToHref(props.homePage.button)} theme={props.homePage.root?.theme}>
								{props.homePage.button?.title}
							</Button>
						)}
					</div>
					<div className={s.GalleryDesktop}>
						{props.homePage.ilustration && imageIndex !== null && (
							<div
								className={s.IlustrationImageWrapper}
								style={{ '--items-count': `${(props.homePage.gallery?.items ?? []).length}` }}
							>
								<span className={s.IlustrationImage}>
									{image && <ContemberImage image={image} objectFit="contain" layout="fill" />}
								</span>

								{props.homePage.gallery?.items.map(
									(item, index) =>
										item.image && (
											<div key={item.id} className={s.Image} style={{ '--item-counter': `${index}` }}>
												<ContemberImage image={item.image} objectFit="cover" />
											</div>
										),
								)}
							</div>
						)}
						<div className={s.Overlay} />
					</div>
				</div>
			</div>
			<Container size="wide">
				<SiteSwitcher
					title={props.homePage.siteSwitcher?.title}
					link={props.homePage.siteSwitcher?.link}
					theme={props.homePage.siteSwitcher?.theme}
				/>
				{props.homePage.content && (
					<div className={s.Content}>
						<ContentRenderer content={props.homePage.content} />
					</div>
				)}
				{props.homePage.stickyBar && <StickyBar stickyBar={props.homePage.stickyBar} />}
			</Container>
		</div>
	)
}
