import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import type { GalleryResult } from '../data/GalleryFragment'
import { CarouselNavigation } from './CarouselNavigation'
import { ContemberImage } from './ContemberImage'
import s from './GridGallery.module.sass'
import { useCarousel } from './useCarousel'

export type GridGalleryProps = {
	title: string
	text?: string
	gallery?: GalleryResult
}

export const GridGallery: FunctionComponent<GridGalleryProps> = ({ title, text, gallery }) => {
	const { cardsElement, onSlideChange, showPreviousButton, showNextButton, scroll } = useCarousel()

	return (
		<div className={s.Wrapper}>
			<div className={s.WrapperIn}>
				<div className={s.TitleWrapper}>
					<h3 className={s.Title}>{title}</h3>
					{text && (
						<div className={s.Text}>
							<RichTextRenderer source={text} />
						</div>
					)}
				</div>
			</div>
			<div className={s.Gallery} ref={cardsElement} onScroll={() => onSlideChange()}>
				{gallery?.items.map(
					(item) =>
						item.image && (
							<div key={item.id} className={s.Image}>
								<div className={s.ImageIn}>
									<ContemberImage image={item.image} objectFit="cover" layout="intrinsic" />
								</div>
							</div>
						),
				)}
			</div>
			<CarouselNavigation
				onPreviousClick={() => scroll(-1)}
				onNextClick={() => scroll(1)}
				isPreviousButtonVisible={showPreviousButton}
				isNextButtonVisible={showNextButton}
			/>
		</div>
	)
}
