import type { FunctionComponent } from 'react'
import s from './Anchor.module.sass'

export type AnchorProps = {
	anchor: string
}

export const Anchor: FunctionComponent<AnchorProps> = ({ anchor }) => {
	return <div className={s.Anchor} id={anchor} />
}
