import type { FunctionComponent } from 'react'
import type { GalleryResult } from '../data/GalleryFragment'
import { ContemberImage } from './ContemberImage'
import s from './Gallery.module.sass'

export type GalleryProps = {
	gallery: GalleryResult
}

export const Gallery: FunctionComponent<GalleryProps> = (props) => {
	return (
		<>
			<div className={s.GalleryMobile}>
				{props.gallery?.items.slice(0, 12).map(
					(item) =>
						item.image && (
							<div key={item.id} className={s.Image}>
								<div className={s.ImageIn}>
									<ContemberImage image={item.image} objectFit="cover" />
								</div>
							</div>
						),
				)}
			</div>
			<div className={s.GalleryDesktop}>
				{props.gallery?.items.map(
					(item) =>
						item.image && (
							<div key={item.id} className={s.Image}>
								<div className={s.ImageIn}>
									<ContemberImage image={item.image} objectFit="cover" />
								</div>
							</div>
						),
				)}
			</div>
		</>
	)
}
