import type { CSSProperties, FunctionComponent } from 'react'
import type { LinkResult } from '../data/LinkFragment'
import type { ThemeResult } from '../data/ThemeFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Icon } from './Icon'
import s from './SiteSwitcher.module.sass'

export type SiteSwitcherProps = {
	title?: string
	link?: LinkResult
	theme?: ThemeResult
}

export const SiteSwitcher: FunctionComponent<SiteSwitcherProps> = ({ title, link, theme }) => {
	return (
		<div
			style={
				{
					'--SiteSwitcher-backgroundColor': theme?.backgroundColor,
					'--SiteSwitcher-textColor': theme?.textColor,
				} as CSSProperties
			}
			className={s.Wrapper}
		>
			<p className={s.Title}>{title}</p>
			{link && (
				<Button type="link" href={contemberLinkToHref(link)} theme={theme}>
					{link?.title}
					<span className={s.Arrow}>
						<Icon name="arrow" />
					</span>
				</Button>
			)}
		</div>
	)
}
