import type { JobPageLocaleResult } from '../data/JobPageLocaleFragment'
import { Button } from './Button'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { Hero } from './Hero'
import { Icon } from './Icon'
import s from './JobPage.module.sass'
import type { CustomJobsFormData } from './JobsFormModal'
import { StickyBar } from './StickyBar'
import { usePageContext } from '../pages/[[...path]]'
import { RichTextRenderer } from '@contember/react-client'
import { Link } from './Link'
import { Fragment } from 'react'
import Head from 'next/head'

export type JobPageProps = {
	jobPage: JobPageLocaleResult
	customJobsFormModalData: CustomJobsFormData
	deactivatedMessage: string | undefined
}

export function JobPage(props: JobPageProps) {
	const page = usePageContext()

	const content = (
		<Container size="wide">
			<div className={s.Wrapper}>
				<Hero
					title={props.jobPage.title}
					text={props.jobPage.text}
					mobileImage={props.jobPage.mobileImage}
					image={props.jobPage.image}
					keyInfo={props.jobPage.keyInfo}
					jobPage
				/>

				{props.jobPage.content && (
					<div className={s.Content}>
						<ContentRenderer content={props.jobPage.content} customJobsFormModalData={props.customJobsFormModalData} />
					</div>
				)}
				{props.jobPage.stickyBar && !!props.jobPage.root?.isActive && <StickyBar stickyBar={props.jobPage.stickyBar} />}
			</div>
		</Container>
	)
	return props.jobPage.root?.isActive ? (
		content
	) : (
		<Fragment>
			<div className={s.Deactivated}>
				<div className={s.DeactivatedModal}>
					<Container size="wide">
						{props.deactivatedMessage && (
							<div className={s.DeactivatedText}>
								<RichTextRenderer source={props.deactivatedMessage} />
							</div>
						)}
						{page.data.getLinkable?.jobPage?.root?.business?.localesByLocale?.link?.url && (
							<div className={s.ButtonWrapper}>
								<Button href="/#jobfilter" type="link" variant="white" className={s.BackButton}>
									<span className={s.ButtonArrow}>
										<Icon name="arrow" />
									</span>
									Zpět na výpis volných pozic
								</Button>
							</div>
						)}
						<Link href="/" className={s.LogoSmall}>
							<Icon name="logoHover" />
						</Link>
					</Container>
				</div>
				<Container size="wide">{content}</Container>
			</div>
			<Head>
				<meta name="robots" content="noindex,nofollow" />
				<meta name="googlebot" content="noindex,nofollow" />
				<meta name="google" content="nositelinkssearchbox" />
			</Head>
		</Fragment>
	)
}
