import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { CSSProperties } from 'react'
import React, { useState } from 'react'
import { i18n } from '../../config'
import type { HeaderLocaleResult } from '../data/HeaderLocaleFragment'
import type { ThemeResult } from '../data/ThemeFragment'
import { filterNonEmpty } from '../utilities/filterNonEmpty'
import { usePageLoadingState } from '../utilities/usePageLoadingState'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Header.module.sass'
import { Icon } from './Icon'
import { Link } from './Link'

export type HeaderProps = {
	header: HeaderLocaleResult
	translations: {
		id: string
		link?: {
			url: string
		}
		locale?: {
			code: string
		}
	}[]
	theme?: ThemeResult
}

export function Header(props: HeaderProps) {
	const [open, setOpen] = useState(false)
	const [loading] = usePageLoadingState()

	React.useEffect(() => {
		setOpen(false)
	}, [loading])

	const translations = props.translations.filter((tr) => tr.locale?.code !== props.header.locale?.code)

	const existingLocales = props.translations.map((tr) => tr.locale?.code).filter(filterNonEmpty)

	const missingLocales = i18n?.locales.filter(
		(locale) => !existingLocales.includes(locale) && locale !== props.header.locale?.code,
	)

	return (
		<div className={s.Wrapper}>
			<Container size="wide">
				<div className={s.HeaderWrapper}>
					<button
						className={s.Hamburger}
						onClick={() => setOpen((old) => !old)}
						style={{ '--Hamburger-color': props.theme?.headerMenuColor } as CSSProperties}
					>
						<div className={s.HamburgerIcon}>
							<span className={s.HamburgerLine} />
							<span className={s.HamburgerLine} />
							<span className={s.HamburgerLine} />
						</div>
						<p className={s.MenuLabel}>{props.header.menuLabel}</p>
					</button>
					{props.header.root?.logo && (
						<div className={s.LogoWrapperIn}>
							<Link
								href="/"
								className={s.LogoFull}
								style={{ '--Logo-color': props.theme?.headerMenuColor } as CSSProperties}
							>
								<Icon name="logo" />
							</Link>
							<Link href="/" className={s.LogoSmall}>
								<Icon name="logoHover" />
							</Link>
						</div>
					)}
					<div className={s.Locales}>
						<div className={s.LocaleWrapper}>
							<div className={clsx(s.Locale, s.isActiveLocale)}>{props.header.locale?.code}</div>
						</div>
						{translations.map((item) => (
							<div key={item.id} className={s.LocaleWrapper}>
								<a
									href={item.link?.url}
									onClick={() => {
										document.cookie = `NEXT_LOCALE=${item.locale?.code}; path=/`
									}}
									className={s.Locale}
								>
									{item.locale?.code}
								</a>
							</div>
						))}
						{missingLocales?.map((locale, i) => (
							<div key={i} className={s.MissingLocale}>
								<a
									className={s.Locale}
									onClick={() => {
										document.cookie = `NEXT_LOCALE=${locale}; path=/`
									}}
									href={locale === i18n?.defaultLocale ? '/' : `/${locale}`}
								>
									{locale}
								</a>
							</div>
						))}
					</div>
				</div>
				<div className={clsx(open && s.isMenuOpenShadow)} onClick={() => setOpen((old) => !old)} />
				<div className={clsx(s.Menu, open && s.isMenuOpen)}>
					<Container size="wide">
						<button className={s.Close} onClick={() => setOpen((old) => !old)}>
							<Icon name="close" />
						</button>
						{props.header.root?.logoMenu && (
							<div className={s.LogoMenu}>
								<ContemberImage image={props.header.root?.logoMenu} />
							</div>
						)}
						<h3 className={s.MenuTitle}>{props.header.menuTitle}</h3>
						{props.header.menuText && (
							<div className={s.MenuText}>
								<RichTextRenderer source={props.header.menuText} />
							</div>
						)}
						<div className={s.MenuSections}>
							<div className={s.MenuSection}>
								<div>
									<p>{props.header.businessSectionTitle}</p>
									<div className={s.Businesses}>
										{props.header.business.map((business) => (
											<Link key={business.id} link={business.link} className={s.Business}>
												{business.logo && (
													<div className={s.BusinessLogo}>
														<ContemberImage image={business.logo} />
													</div>
												)}
												<p className={s.BusinessName}>{business.link?.title}</p>
											</Link>
										))}
									</div>
								</div>
								<Link onClick={() => setOpen(false)} className={s.MenuSectionLink} link={props.header.businessLink} />
							</div>
							<div className={s.MenuSection}>
								<div>
									<p>{props.header.lokalSectionTitle}</p>
									<div className={s.Businesses}>
										{props.header.lokalEnterprise.map((business) => (
											<Link key={business.id} link={business.link} className={s.BusinessLokal}>
												{business.logo && (
													<div className={s.BusinessLogo}>
														<ContemberImage image={business.logo} />
													</div>
												)}
												<p className={s.BusinessName}>{business.link?.title}</p>
											</Link>
										))}
									</div>
								</div>
								<Link onClick={() => setOpen(false)} className={s.MenuSectionLink} link={props.header.lokalLink} />
							</div>
						</div>
					</Container>
				</div>
			</Container>
		</div>
	)
}
