import clsx from 'clsx'
import type { FunctionComponent } from 'react'
// import type { CarouselCardsProps } from './CarouselCards'
import s from './CarouselNavigation.module.sass'
import { Icon } from './Icon'

export type CarouselNavigationProps = {
	onPreviousClick: () => void
	onNextClick: () => void
	isPreviousButtonVisible: boolean
	isNextButtonVisible: boolean
}

export const CarouselNavigation: FunctionComponent<CarouselNavigationProps> = ({
	onPreviousClick,
	onNextClick,
	isPreviousButtonVisible,
	isNextButtonVisible,
}) => {
	return (
		<div className={clsx(s.Wrapper)}>
			<button
				type="button"
				className={clsx(
					s.Arrow,
					s.Previous,
					isNextButtonVisible && s.is_visible,
					isPreviousButtonVisible && s.is_clickable,
				)}
				onClick={onPreviousClick}
				aria-label="previous"
			>
				<Icon name="arrow" />
			</button>
			<button
				type="button"
				className={clsx(s.Arrow, isPreviousButtonVisible && s.is_visible, isNextButtonVisible && s.is_clickable)}
				onClick={onNextClick}
				aria-label="next"
			>
				<Icon name="arrow" />
			</button>
		</div>
	)
}
