import { RichTextRenderer } from '@contember/react-client'
import type { RecommendationFormLocaleResult } from '../data/RecommendationFormLocaleFragment'
import { Container } from './Container'
import { Icon } from './Icon'
import { RecommendationForm } from './RecommendationForm'
import s from './RecommendationFormModal.module.sass'

export type CustomRecommendationFormData = {
	content: React.ReactNode
	jobPageId: string
}

export type RecommendationFormModalProps = {
	recommendationFormModal: RecommendationFormLocaleResult
	onRequestClose?: () => void
}

export function RecommendationFormModal(props: RecommendationFormModalProps) {
	return (
		<Container size="wide" disableGutters>
			<div className={s.Wrapper}>
				<button className={s.CloseBtn} type="button" onClick={() => props.onRequestClose?.()}>
					<Icon name="close" />
				</button>
				<div className={s.FormTitleWrapper}>
					<h3 className={s.Title}>{props.recommendationFormModal.title}</h3>
					{props.recommendationFormModal.subtitle && (
						<div className={s.Subtitle}>
							<RichTextRenderer source={props.recommendationFormModal.subtitle} />
						</div>
					)}
				</div>
				<div className={s.FormWrapper}>
					<RecommendationForm
						recommendationForm={props.recommendationFormModal}
						onRequestClose={props.onRequestClose}
					/>
				</div>
			</div>
		</Container>
	)
}
