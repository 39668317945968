import type { ReferenceRendererProps } from '@contember/react-client'
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import type { ContentReferenceType } from '../../generated/contember'
import type { ContentBlockResult } from '../data/ContentBlockFragment'
import type { ContentResult } from '../data/ContentFragment'
import { usePageContext } from '../pages/[[...path]]'
import { filterNonEmpty } from '../utilities/filterNonEmpty'
import { useContentRendererCopyPasteBugWorkaround } from '../utilities/useContentRendererCopyPasteBugWorkaround'
import { Anchor } from './Anchor'
import { ArticleCarousel } from './ArticleCarousel'
import { BonusBanner } from './BonusBanner'
import { Carousel } from './Carousel'
import { Collapsible } from './Collapsible'
import { Container } from './Container'
import styles from './ContentRenderer.module.sass'
import { Gallery } from './Gallery'
import { Grid } from './Grid'
import { GridGallery } from './GridGallery'
import { JobFilterComponent } from './JobFilterComponent'
import type { CustomJobsFormData } from './JobsFormModal'
import { Linkables } from './Linkables'
import { ResponsiveImage } from './ResponsiveImage'
import { SideBySideContent } from './SideBySideContent'
import { Statistics } from './Statistics'
import { Video } from './Video'
import { Wysiwyg } from './Wysiwyg'

export interface ContentRendererProps {
	content: ContentResult
	containerDisableGutters?: boolean
	customJobsFormModalData?: CustomJobsFormData
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor', 'tableCell', 'tableRow', 'scrollTarget']

const referenceRenderers: {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} = {
	image: function image({ reference }) {
		return (
			reference.image && (
				<Container>
					<ResponsiveImage
						src={reference.image.url}
						width={reference.image.width}
						height={reference.image.height}
						alt={reference.image.alt ?? ''}
					/>
				</Container>
			)
		)
	},
	gallery: function gallery({ reference }) {
		return (
			reference.gallery && (
				<Container size="wide">
					<Gallery gallery={reference.gallery} />
				</Container>
			)
		)
	},
	video: function video({ reference }) {
		return (
			reference.video && (
				<Container>
					<Video video={reference.video} />
				</Container>
			)
		)
	},
	linkables: function linkables({ reference }) {
		return (
			<Container>
				<Linkables title={reference.primaryText} items={reference.linkables} />
			</Container>
		)
	},
	sideBySideContent: function sideBySideContent({ reference }) {
		return reference.sideBySide && <SideBySideContent sideBySide={reference.sideBySide} />
	},
	statistics: function statistics({ reference }) {
		return (
			<Statistics
				title={reference.primaryText}
				textList={reference.textList}
				link={reference.link}
				isRed={reference.isRed}
			/>
		)
	},
	collapsible: function collapsible({ reference }) {
		return <Collapsible title={reference.primaryText} text={reference.secondaryText} textList={reference.textList} />
	},
	carousel: function carousel({ reference }) {
		return (
			<Carousel
				title={reference.primaryText}
				text={reference.secondaryText}
				squareImages={reference.carousel?.squareImages}
				background={reference.carousel?.background}
				items={reference.carousel?.items ?? []}
			/>
		)
	},
	bonusBanner: function bonusBanner({ reference }) {
		return reference.bonusBanner && <BonusBanner bonusBanner={reference.bonusBanner} />
	},
	grid: function grid({ reference }) {
		return (
			reference.primaryText && (
				<Grid title={reference.primaryText} text={reference.secondaryText} grid={reference.textList} />
			)
		)
	},
	gridGallery: function gridGallery({ reference }) {
		return (
			reference.primaryText && (
				<GridGallery title={reference.primaryText} text={reference.secondaryText} gallery={reference.gallery} />
			)
		)
	},
	jobFilter: function Block({ reference }) {
		const page = usePageContext()
		return (
			reference.primaryText && (
				<JobFilterComponent
					title={reference.primaryText}
					button={reference.secondaryText}
					showBanner={reference.showBoolean}
					specificBusinessId={page.data.getLinkable?.businessPage?.id ?? null}
					specificJobPositionSlug={page.data.getLinkable?.jobPage?.root?.jobPosition?.localesByLocale?.slug ?? null}
					specificJobPageId={page.data.getLinkable?.jobPage?.root?.id ?? null}
				/>
			)
		)
	},
	anchor: function anchro({ reference }) {
		return reference.primaryText && <Anchor anchor={reference.primaryText} />
	},
	articlesCarousel: function ({ reference }) {
		const ids = reference.textList?.items.map((item) => item.text).filter(filterNonEmpty) ?? []

		return <ArticleCarousel title={reference.primaryText} text={reference.secondaryText} ids={ids} />
	},
}

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	containerDisableGutters = false,
}) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)

	return (
		<div className={styles.wrapper}>
			<RichTextRenderer
				blocks={blocks}
				sourceField="json"
				renderElement={(element) => {
					const { type } = element.element

					if (type === 'table') {
						return (
							<div className={clsx(styles.section, styles[`is_reference_${type}`])}>
								<Container>{element.fallback}</Container>
							</div>
						)
					}

					if (nestedTypes.includes(type)) {
						return element.fallback
					}

					if (standaloneTypes.includes(type)) {
						return (
							<div
								className={clsx(
									styles.section,
									element.referenceType && styles[`is_reference_${element.referenceType}`],
								)}
							>
								{type !== 'reference' || !element.referenceType || element.referenceType in referenceRenderers ? (
									element.fallback
								) : (
									<Container disableGutters={containerDisableGutters}>
										<div className={styles.notImplemented}>
											<div className={styles.notImplemented_name}>{element.referenceType}</div>
											is not yet implemented
										</div>
									</Container>
								)}
							</div>
						)
					}
					return (
						<div className={clsx(styles.section, styles.is_wysiwyg)}>
							<Container disableGutters={containerDisableGutters}>
								<Wysiwyg>{element.fallback}</Wysiwyg>
							</Container>
						</div>
					)
				}}
				referenceRenderers={referenceRenderers}
			/>
		</div>
	)
}
