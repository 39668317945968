import { RichTextRenderer } from '@contember/react-client'
import { useState } from 'react'
import Modal from 'react-modal'
import type { FooterLocaleResult } from '../data/FooterLocaleFragment'
import type { ThemeResult } from '../data/ThemeFragment'
import { Button } from './Button'
import { Container } from './Container'
import s from './Footer.module.sass'
import { Icon } from './Icon'
import type { CustomJobsFormData } from './JobsFormModal'
import { JobsFormModal } from './JobsFormModal'
import { Link } from './Link'
import { RecommendationFormModal } from './RecommendationFormModal'

export type FooterProps = {
	footer: FooterLocaleResult
	theme?: ThemeResult
	customJobsFormModalData?: CustomJobsFormData
}

export function Footer(props: FooterProps) {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isRecommendationModalOpen, setIsRecommendationModalOpen] = useState(false)

	const customStyles = {
		content: {
			inset: '0',
			border: 'none',
			borderRadius: '0',
		},
		overlay: {
			zIndex: 2,
		},
	}

	const backgroundColor = `rgba(46, 46, 46, 0.6)`

	const customRecommendationStyles = {
		content: {
			inset: 0,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100%',
			backgroundColor: backgroundColor,
			border: 'none',
			borderRadius: '0',
		},
		overlay: {
			zIndex: 2,
		},
	}
	return (
		<>
			<div className={s.Wrapper}>
				<Container size="wide">
					<div className={s.Section}>
						<div>
							{props.footer.email && (
								<p className={s.Email}>
									Email: <Link href={`mailto:${props.footer.email}`}>{props.footer.email}</Link>
								</p>
							)}
							{props.footer.form && (
								<div className={s.FormBtn}>
									<Button type="button" onClick={() => setIsModalOpen(true)} variant="white">
										{props.footer.form}
									</Button>
								</div>
							)}
							{props.footer.jobsForm && (
								<Modal isOpen={isModalOpen} style={customStyles}>
									<JobsFormModal
										jobsFormModal={props.footer.jobsForm}
										onRequestClose={() => {
											setIsModalOpen(false)
										}}
										customModalData={props.customJobsFormModalData}
									/>
								</Modal>
							)}
							{props.footer.recommendationForm && (
								<Modal isOpen={isRecommendationModalOpen} style={customRecommendationStyles}>
									<RecommendationFormModal
										recommendationFormModal={props.footer.recommendationForm}
										onRequestClose={() => {
											setIsRecommendationModalOpen(false)
										}}
									/>
								</Modal>
							)}
						</div>
						<div className={s.Links}>
							{props.footer.linksFirstColumn.map((item) => (
								<Link key={item.id} link={item.link} className={s.Link} />
							))}
						</div>
						<div className={s.Links}>
							{props.footer.linksSecondColumn.map((item) => (
								<Link key={item.id} link={item.link} className={s.Link} />
							))}
							{props.footer.instagramLink && (
								<Link link={props.footer.instagramLink} className={s.InstagramLink}>
									<Icon name="instagram" />
									<div className={s.InstagramLabel}>{props.footer.instagramLink.title}</div>
								</Link>
							)}
						</div>
						{props.footer.address && (
							<div className={s.Address}>
								<RichTextRenderer source={props.footer.address} />
							</div>
						)}
						<div>
							<p className={s.IcoDic}>{props.footer.ico}</p>
							<p className={s.IcoDic}>{props.footer.dic}</p>
						</div>
					</div>
					<div className={s.Line} />
					<div className={s.Section}>
						<div className={s.CopyrightWrapper}>
							<Link className={s.CopyrightLogo} link={props.footer.copyrightLink}>
								<Icon name="logoHover" />
							</Link>
							{props.footer.copyright && (
								<p className={s.Copyright}>
									<RichTextRenderer source={props.footer.copyright} />
								</p>
							)}
						</div>
						<Link className={s.TermsAndConditions} link={props.footer.termsAndConditions} />
						{props.footer.note && (
							<div className={s.Note}>
								<RichTextRenderer source={props.footer.note} />
							</div>
						)}
						{props.footer.noteSecond && (
							<div className={s.Note}>
								<RichTextRenderer source={props.footer.noteSecond} />
							</div>
						)}
					</div>
				</Container>
			</div>
		</>
	)
}
