import Image from 'next/legacy/image'
import React from 'react'
import type { ImageResult } from '../data/ImageFragment'

type ImageProps = React.ComponentProps<typeof Image>

export function ContemberImage(props: {
	layout?: ImageProps['layout']
	objectFit?: ImageProps['objectFit']
	objectPosition?: ImageProps['objectPosition']
	onLoad?: ImageProps['onLoad']
	loading?: ImageProps['loading']
	sizes?: string
	image: ImageResult
}) {
	const {
		image: { url, width, height, focalPointX, focalPointY },
	} = props
	if (!url && !width && !height) {
		return null
	}

	const imageObjectPosition =
		typeof focalPointX === 'number' && typeof focalPointY === 'number'
			? `${focalPointX * 100}% ${focalPointY * 100}%`
			: undefined

	const objectPosition = props.objectPosition ?? imageObjectPosition

	const baseProps = {
		sizes: props.sizes,
		loading: props.loading,
		objectFit: props.objectFit,
		objectPosition,
		onLoad: props.onLoad,
		unoptimized: process.env.NODE_ENV === 'development',
	}

	if (props.layout === 'fill') {
		return <Image {...baseProps} src={url} alt={props.image.alt ?? ''} layout={props.layout} />
	}
	return (
		<Image
			{...baseProps}
			src={url}
			width={width}
			height={height}
			alt={props.image.alt ?? ''}
			layout={props.layout}
			unoptimized
		/>
	)
}
