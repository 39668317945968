/* eslint-disable no-mixed-spaces-and-tabs */
import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import Modal from 'react-modal'
import type { BonusBannerLocaleResult } from '../data/BonusBannerLocaleFragment'
import { usePageContext } from '../pages/[[...path]]'
import s from './BonusBannerModal.module.sass'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import { JobModalInfo } from './JobModalInfo'
import type { CustomJobsFormData } from './JobsFormModal'
import { JobsFormModal } from './JobsFormModal'

export type BonusBannerModalProps = {
	tiles: BonusBannerLocaleResult['tiles']
	activeTile: number
	setActiveTile?: (index: null | number) => void
}

export function BonusBannerModal(props: BonusBannerModalProps) {
	const tile = props.tiles[props.activeTile]

	const [isModalFormOpen, setIsModalFormOpen] = useState(false)

	const pageProps = usePageContext()

	const customJobsFormModalData: CustomJobsFormData | undefined = pageProps?.data.getLinkable?.jobPage
		? {
				jobPageId: pageProps.data.getLinkable.jobPage.id,
				content: <JobModalInfo jobForm={pageProps.data.getLinkable.jobPage} />,
			}
		: undefined

	const customStyles = {
		content: {
			inset: '0',
			border: 'none',
			borderRadius: '0',
		},
		overlay: {
			zIndex: 2,
		},
	}

	const [videoDuration, setVideoDuration] = useState<null | number>(null)

	const next = useCallback(() => {
		props.setActiveTile?.(props.tiles.length > props.activeTile + 1 ? props.activeTile + 1 : 0)
	}, [props])

	const previous = useCallback(() => {
		props.setActiveTile?.(props.activeTile > 0 ? props.activeTile - 1 : props.tiles.length - 1)
	}, [props])

	const sectionTitles = (
		<>
			{props.tiles.map((tile, i) => {
				const hasVideo = tile.ligthBoxMedia?.items.some((item) => item.medium?.type === 'video')

				return (
					<button
						key={tile.id}
						onClick={() => props.setActiveTile?.(i)}
						className={clsx(s.SectionTitle, hasVideo && s.hasVideo, props.activeTile === i && s.titleActive)}
						onAnimationEnd={hasVideo ? undefined : next}
					>
						{tile.lightBoxSectionTitle}
					</button>
				)
			})}
		</>
	)

	useEffect(() => {
		const handleKey = (event: KeyboardEvent) => {
			if (event.code === 'ArrowRight') {
				next()
			}
			if (event.code === 'ArrowLeft') {
				previous()
			}
		}
		window.addEventListener('keydown', handleKey)
		return () => {
			window.removeEventListener('keydown', handleKey)
		}
	}, [next, previous])

	return (
		<div className={s.Wrapper} style={{ '--video-duration': `${videoDuration}s` }}>
			<Container size="fullWidth" disableGutters>
				<button className={s.CloseBtn} type="button" onClick={() => props.setActiveTile?.(null)}>
					<Icon name="close" />
				</button>
				<div className={s.Gradient} />
				{props.tiles && <div className={s.SectionMobileTitles}>{sectionTitles}</div>}
				<div className={s.ContentWrapper}>
					<div
						className={clsx(
							s.LightBoxMediaWrapper,
							tile.ligthBoxMedia && tile.ligthBoxMedia?.items.length >= 2 && s.isMoreMedia,
						)}
						onClick={previous}
					>
						{tile.ligthBoxMedia?.items.map((item) => {
							const type = item.medium?.type
							return (
								tile.ligthBoxMedia?.items && (
									<div
										key={item.id}
										className={clsx(s.LightBoxMedia, tile.ligthBoxMedia?.items.length >= 2 && s.isSmallerImage)}
									>
										{type === 'image' && item.medium?.image ? (
											<div className={s.Image}>
												<ContemberImage image={item.medium.image} layout="fill" objectFit="cover" />
											</div>
										) : type === 'video' && item.medium?.video ? (
											<video
												className={s.Video}
												autoPlay
												muted
												playsInline
												poster={item.medium.video?.poster?.url}
												onPlay={(e) => {
													setVideoDuration(e.currentTarget.duration - e.currentTarget.currentTime)
												}}
												onEnded={next}
											>
												<source src={item.medium.video.src} type={item.medium.video.type} />
											</video>
										) : null}
									</div>
								)
							)
						})}
					</div>
					<div className={s.Content} onClick={next}>
						{props.tiles && <div className={s.SectionDesktopTitles}>{sectionTitles}</div>}
						<div>
							<h3 className={s.LightBoxTitle}>{tile.lightBoxTitle}</h3>
							<p className={s.LightBoxText}>{tile.lightBoxText}</p>
							{tile.lightBoxLinkLabel && (
								<div
									className={clsx(
										s.LightBoxLink,
										tile.ligthBoxMedia && tile.ligthBoxMedia?.items.length >= 2 && s.isMoreMediaLink,
									)}
								>
									<Button type="button" onClick={() => setIsModalFormOpen(true)} variant="red">
										{tile.lightBoxLinkLabel}
									</Button>
								</div>
							)}
							<div className={s.ArrowMobileBtns}>
								{props.activeTile + 1 && (
									<button
										className={clsx(s.ArrowMobileBtn, s.Previous)}
										type="button"
										onClick={previous}
										aria-label="další"
									>
										<Icon name="arrow" />
									</button>
								)}
								{props.activeTile + 1 && (
									<button className={s.ArrowMobileBtn} type="button" onClick={next} aria-label="další">
										<Icon name="arrow" />
									</button>
								)}
							</div>

							{tile.lightBoxForm && (
								<Modal isOpen={isModalFormOpen} style={customStyles}>
									<JobsFormModal
										jobsFormModal={tile.lightBoxForm}
										onRequestClose={() => {
											setIsModalFormOpen(false)
										}}
										customModalData={customJobsFormModalData}
									/>
								</Modal>
							)}
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}
