import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import Modal from 'react-modal'
import type { BonusBannerLocaleResult } from '../data/BonusBannerLocaleFragment'
import s from './BonusBanner.module.sass'
import { BonusBannerModal } from './BonusBannerModal'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'

export type BonusBannerProps = {
	bonusBanner: BonusBannerLocaleResult
}

export const BonusBanner: FunctionComponent<BonusBannerProps> = ({ bonusBanner }) => {
	const [activeModalTileIndex, setIsModalOpen] = useState<null | number>(null)

	const customStyles = {
		content: {
			inset: '0',
			border: 'none',
			borderRadius: '0',
		},
		overlay: {
			zIndex: 2,
			inset: '-2px',
		},
	}

	return (
		<div className={s.Wrapper}>
			<Container size="wide">
				<Modal isOpen={typeof activeModalTileIndex === 'number'} style={customStyles}>
					{typeof activeModalTileIndex === 'number' && (
						<BonusBannerModal
							tiles={bonusBanner.tiles}
							activeTile={activeModalTileIndex}
							setActiveTile={(index) => {
								setIsModalOpen(index)
							}}
						/>
					)}
				</Modal>
				<div className={s.HeadingWrapper}>
					<div>
						<h3>{bonusBanner.title}</h3>
						{bonusBanner.text && (
							<div className={s.HeadingText}>
								<RichTextRenderer source={bonusBanner.text} />
							</div>
						)}
					</div>
					{bonusBanner.logo && (
						<div className={s.Logo}>
							<ContemberImage image={bonusBanner.logo} />
						</div>
					)}
				</div>
				<div className={s.Banners}>
					{bonusBanner.tiles.map((item, i) => (
						<button key={item.id} className={s.Banner} onClick={() => setIsModalOpen(i)}>
							{item.image && (
								<div className={s.MobileImage}>
									<ContemberImage image={item.image} />
								</div>
							)}
							<div className={s.TitleWrapper}>
								<p className={s.BannerTitle}>{item.title}</p>
								{item.image && (
									<div className={s.DesktopImage}>
										<ContemberImage image={item.image} />
									</div>
								)}
							</div>
							<div>
								{item.text && (
									<div className={s.BannerText}>
										<RichTextRenderer source={item.text} />
									</div>
								)}
								<div className={s.Plus}>
									<Icon name="plus" />
								</div>
							</div>
						</button>
					))}
				</div>
			</Container>
		</div>
	)
}
