import type { GenericPageLocaleResult } from '../data/GenericPageLocaleFragment'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import s from './GenericPage.module.sass'
import { Hero } from './Hero'

export type GenericPageProps = {
	genericPage: GenericPageLocaleResult
}

export function GenericPage(props: GenericPageProps) {
	return (
		<Container size="wide">
			<div className={s.Wrapper}>
				<Hero
					title={props.genericPage.title}
					text={props.genericPage.text}
					image={props.genericPage.image}
					logo={props.genericPage.logo}
					genericPage
				/>
				{props.genericPage.content && (
					<div className={s.Content}>
						<ContentRenderer content={props.genericPage.content} />
					</div>
				)}
			</div>
		</Container>
	)
}
