import { useState } from 'react'
import Modal from 'react-modal'
import { useMirrorLoading } from 'shared-loading-indicator'
import type { RecommendationFormLocaleResult } from '../data/RecommendationFormLocaleFragment'
import { api } from '../utilities/api'
import { Button } from './Button'
import { Icon } from './Icon'
import s from './RecommendationForm.module.sass'
import { RecommendationFormSuccessModal } from './RecommendationFormSuccessModal'

export type RecommendationFormProps = {
	recommendationForm: RecommendationFormLocaleResult
	onRequestClose?: () => void
}

export function RecommendationForm(props: RecommendationFormProps) {
	const [successModalOpen, setSuccessModalOpen] = useState(false)
	const [yourEmail, setYourEmail] = useState('')
	const [theirEmail, setTheirEmail] = useState('')
	const [yourName, setYourName] = useState('')

	const recommendationFormLocaleId = props.recommendationForm.id
	const backgroundColor = `rgba(46, 46, 46, 0.6)`
	const customStyles = {
		content: {
			inset: 0,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100%',
			backgroundColor: backgroundColor,
			border: 'none',
			borderRadius: '0',
		},
		overlay: {
			zIndex: 2,
		},
	}

	const mutation = api.recommendatinFormSubcription.send.useMutation({
		async onSuccess() {
			setSuccessModalOpen(true)
		},
		onError: () => {
			alert('Něco se při odesílání pokazilo. Zkuste to znovu prosím později.')
		},
	})
	const isLoading = mutation.isLoading
	useMirrorLoading(isLoading)

	return (
		<form
			onSubmit={(event) => {
				event.preventDefault()
				mutation.mutate({
					recommendationFormLocaleId,
					yourEmail,
					theirEmail,
					yourName,
				})
			}}
		>
			<label className={s.InputName}>
				<p className={s.InputLabel}>{props.recommendationForm.yourEmailLabel} *</p>
				<input
					type="email"
					className={s.Input}
					autoComplete="name"
					value={yourEmail}
					onChange={(event) => {
						setYourEmail(event.target.value)
					}}
					required
				/>
			</label>
			<label className={s.InputName}>
				<p className={s.InputLabel}>{props.recommendationForm.theirEmailLabel} *</p>
				<input
					type="email"
					className={s.Input}
					autoComplete="email"
					value={theirEmail}
					onChange={(event) => {
						setTheirEmail(event.target.value)
					}}
					required
				/>
			</label>
			<label className={s.InputName}>
				<p className={s.InputLabel}>{props.recommendationForm.yourName} *</p>
				<input
					type="tel"
					className={s.Input}
					autoComplete="tel"
					value={yourName}
					onChange={(event) => {
						setYourName(event.target.value)
					}}
					required
				/>
			</label>

			<div className={s.Submit}>
				<Button type="submit" variant="red" isFullWidth isWithIcon disabled={isLoading}>
					{props.recommendationForm.sendBtnLabel}
					<span className={s.Checkmark}>
						<Icon name="checkmark" />
					</span>
				</Button>
			</div>

			<Modal isOpen={successModalOpen} style={customStyles}>
				<RecommendationFormSuccessModal
					image={props.recommendationForm.root?.successImage}
					title={props.recommendationForm.successTitle}
					text={props.recommendationForm.successSubtitle}
					button={props.recommendationForm.successButtonClose}
					onRequestClose={() => {
						props.onRequestClose?.()
						setSuccessModalOpen(false)
					}}
				/>
			</Modal>
		</form>
	)
}
