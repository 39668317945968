import clsx from 'clsx'
import type { DistinctBaseButtonProps, SharedBaseButtonProps } from 'nextjs-button-base'
import { ButtonBase } from 'nextjs-button-base'
import type { CSSProperties, FunctionComponent } from 'react'
import type { ThemeResult } from '../data/ThemeFragment'
import s from './Button.module.sass'

export type ButtonProps = {
	className?: string
	isFullWidth?: boolean
	isWithIcon?: boolean
	variant?: 'red' | 'white' | 'yellow'
	theme?: ThemeResult
} & Omit<SharedBaseButtonProps, 'className'> &
	DistinctBaseButtonProps

export const Button: FunctionComponent<ButtonProps> = ({
	className,
	children,
	isFullWidth = false,
	isWithIcon = false,
	variant = 'primary',
	theme,
	...otherProps
}) => {
	return (
		<ButtonBase
			className={clsx(
				className,
				s.Wrapper,
				s[`is_variant_${variant}`],
				isFullWidth && s.is_fullWidth,
				isWithIcon && s.is_withIcon,
			)}
			{...otherProps}
			style={
				{
					'--Button-textColor': theme?.buttonTextColor,
					'--Button-backgroundColor': theme?.buttonBackgroundColor,
					'--Button-borderColor': theme?.buttonBorderColor,
					'--Button-textHoverColor': theme?.buttonTextHoverColor,
					'--Button-backgroundHoverColor': theme?.buttonBackgroundHoverColor,
					'--Button-borderHoverColor': theme?.buttonBorderHoverColor,
				} as CSSProperties
			}
		>
			{children}
		</ButtonBase>
	)
}
