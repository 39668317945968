import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { useContext, useMemo, useState } from 'react'
import Modal from 'react-modal'
import { PageThemeContext } from '../contexts/pageThemeContext'
import { usePageContext } from '../pages/[[...path]]'
import { api } from '../utilities/api'
import { isDefined } from '../utilities/isDefined'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import { JobFilter } from './JobFilter'
import s from './JobFilterComponent.module.sass'
import { JobsFormModal } from './JobsFormModal'

export type JobFilterComponentProps = {
	title?: string
	button?: string
	showBanner?: boolean
	specificBusinessId: string | null
	specificJobPositionSlug: string | null
	specificJobPageId: string | null
}

export const JobFilterComponent: FunctionComponent<JobFilterComponentProps> = ({
	title,
	button,
	showBanner,
	specificBusinessId,
	specificJobPositionSlug,
	specificJobPageId,
}) => {
	const pageProps = usePageContext()

	const [jobPositionActiveSlugs, setJobPositionActiveSlugs] = useState<string[]>(
		specificJobPositionSlug ? [specificJobPositionSlug] : [],
	)
	const [contractTypeActiveSlugs, setContractTypeActiveSlugs] = useState<string[]>([])
	const [jobCityActiveSlugs, setJobCityActiveSlugs] = useState<string[]>([])

	const locale = pageProps?.locale

	const filter = api.jobFilter.infiniteJobs.useInfiniteQuery(
		{
			locale: locale ?? 'cs',
			contractTypeActiveSlugs,
			jobCityActiveSlugs,
			jobPositionActiveSlugs,
			specificBusinessId,
			specificJobPageId,
			type: pageProps.data.getLinkable?.homePageLokal ? 'lokal' : undefined,
		},
		{ getNextPageParam: (lastPage) => lastPage.nextCursor, keepPreviousData: true },
	)

	const jobs = useMemo(() => {
		return (
			filter.data?.pages.flatMap((page) => page.data.paginateJobPageLocale.edges.flatMap((edge) => edge.node)) ?? []
		)
	}, [filter.data?.pages])

	const info = filter.data?.pages[0]?.data

	const theme = useContext(PageThemeContext)

	const [isModalOpen, setIsModalOpen] = useState(false)

	const customStyles = {
		content: {
			inset: '0',
			border: 'none',
			borderRadius: '0',
		},
		overlay: {
			zIndex: 2,
		},
	}

	return (
		<div className={s.Wrapper}>
			<Container size="wide">
				<h3 className={s.Title}>{title}</h3>
				{!specificBusinessId && (
					<div className={s.FilterSections}>
						<JobFilter
							setActiveSlugs={setJobPositionActiveSlugs}
							activeSlugs={jobPositionActiveSlugs}
							heading={info?.getJobsFilterLocale?.jobPositionLabel ?? ''}
							filters={(info?.listJobPositionLocale ?? [])
								.map((position) => ({ slug: position.slug, label: position.type }))
								.filter(isDefined)}
						/>
						<JobFilter
							setActiveSlugs={setContractTypeActiveSlugs}
							activeSlugs={contractTypeActiveSlugs}
							heading={info?.getJobsFilterLocale?.jobContractLabel ?? ''}
							filters={(info?.listContractTypeLocale ?? [])
								.map((contract) => ({ slug: contract.slug, label: contract.type }))
								.filter(isDefined)}
						/>
						<JobFilter
							setActiveSlugs={setJobCityActiveSlugs}
							activeSlugs={jobCityActiveSlugs}
							heading={info?.getJobsFilterLocale?.jobCityLabel ?? ''}
							filters={(info?.listJobCityLocale ?? [])
								.map((city) => ({ slug: city.slug, label: city.name }))
								.filter(isDefined)}
						/>
					</div>
				)}
				<div className={s.FilterTags}>
					{info?.listJobPositionLocale
						.filter((item) => jobPositionActiveSlugs.includes(item.slug))
						.map((item) => (
							<div key={item.slug} className={s.FilterTag}>
								<div className={s.FilterTagName}>{item.type}</div>
								<button
									className={s.FilterTagRemove}
									onClick={() => {
										setJobPositionActiveSlugs((old) => old.filter((slug) => slug !== item.slug))
									}}
								>
									<Icon name="close" />
								</button>
							</div>
						))}
					{info?.listContractTypeLocale
						.filter((item) => contractTypeActiveSlugs.includes(item.slug))
						.map((item) => (
							<div key={item.slug} className={s.FilterTag}>
								<div className={s.FilterTagName}>{item.type}</div>
								<button
									className={s.FilterTagRemove}
									onClick={() => {
										setContractTypeActiveSlugs((old) => old.filter((slug) => slug !== item.slug))
									}}
								>
									<Icon name="close" />
								</button>
							</div>
						))}
					{info?.listJobCityLocale
						.filter((item) => jobCityActiveSlugs.includes(item.slug))
						.map((item) => (
							<div key={item.slug} className={s.FilterTag}>
								<div className={s.FilterTagName}>{item.name}</div>
								<button
									className={s.FilterTagRemove}
									onClick={() => {
										setJobCityActiveSlugs((old) => old.filter((slug) => slug !== item.slug))
									}}
								>
									<Icon name="close" />
								</button>
							</div>
						))}
				</div>
				{jobs.length === 0 && specificBusinessId && (
					<div className={s.NoFilter}>
						{info?.getJobsFilterLocale?.noJobImage && <ContemberImage image={info?.getJobsFilterLocale?.noJobImage} />}
						<p className={s.NoFilterTitle}>{info?.getJobsFilterLocale?.noJobTitleForSpecificBusiness}</p>
					</div>
				)}
				{jobs.length === 0 && !specificBusinessId && (
					<div className={s.NoFilter}>
						{info?.getJobsFilterLocale?.noJobImage && <ContemberImage image={info?.getJobsFilterLocale?.noJobImage} />}
						<p className={s.NoFilterTitle}>{info?.getJobsFilterLocale?.noJobTitle}</p>
						<Button
							onClick={() => {
								setContractTypeActiveSlugs([])
								setJobCityActiveSlugs([])
								setJobPositionActiveSlugs([])
							}}
							type="button"
							variant="white"
						>
							{info?.getJobsFilterLocale?.noJobBtnLabel}
						</Button>
					</div>
				)}
				{jobs.length >= 1 && (
					<div className={s.Jobs}>
						{jobs.map((job) => (
							<a href={job.link?.url} key={job.id} className={s.Job}>
								{job.image && (
									<div className={s.JobImage}>
										<ContemberImage objectFit="cover" image={job.image} />
									</div>
								)}
								<div className={s.JobInfoBox}>
									<div>
										<h4 className={s.JobTitle}>{job.title}</h4>
										{job.text && (
											<div className={s.JobText}>
												<RichTextRenderer source={job.text} />
											</div>
										)}
									</div>
									<div className={s.JobLabels}>
										<p className={s.JobLabel}>{job.root?.contractType?.localesByLocale?.type}</p>
										<p className={s.JobLabel}>{job.root?.jobCity?.localesByLocale?.name}</p>
									</div>
								</div>
							</a>
						))}
					</div>
				)}
				{filter.hasNextPage && (
					<div className={s.Button}>
						<Button
							type="button"
							onClick={() => {
								if (!filter.isFetchingNextPage) {
									filter.fetchNextPage()
								}
							}}
							isFullWidth
							theme={theme}
						>
							{button}
						</Button>
					</div>
				)}
			</Container>
			{showBanner && (
				<Container size="wide">
					<div className={s.Line} />
					<div className={s.BannerWrapper}>
						<Container size="wide">
							<div className={s.Banner}>
								<div className={s.Content}>
									<h2 className={s.BannerTitle}>{info?.getJobsFilterLocale?.bannerTitle}</h2>
									{info?.getJobsFilterLocale?.bannerFormLabel && (
										<div className={s.BannerLink}>
											<Button type="button" onClick={() => setIsModalOpen(true)} theme={theme}>
												{info?.getJobsFilterLocale?.bannerFormLabel}
											</Button>
										</div>
									)}
									{info?.getJobsFilterLocale?.bannerJobsForm && (
										<Modal isOpen={isModalOpen} style={customStyles}>
											<JobsFormModal
												jobsFormModal={info?.getJobsFilterLocale?.bannerJobsForm}
												onRequestClose={() => {
													setIsModalOpen(false)
												}}
											/>
										</Modal>
									)}
								</div>
								{info?.getJobsFilterLocale?.bannerImage && (
									<div className={s.Image}>
										<ContemberImage image={info.getJobsFilterLocale.bannerImage} />
									</div>
								)}
							</div>
						</Container>
					</div>
				</Container>
			)}
		</div>
	)
}
